import cooper from '../assets/references/coopermockup.webp'
import cooperTablet from '../assets/references/coopertablet.png'
import lpo from '../assets/references/lpomockup.webp'
import lpoTablet from '../assets/references/lpotablet.png'
import ppc from '../assets/references/ppcmockup.webp'
import ppcTablet from '../assets/references/ppctablet.png'
import dev from '../assets/references/devmockup.webp'
import devTablet from '../assets/references/devtablet.png'
import kozpark from '../assets/references/kozparkmockup.webp'
import kozparkTablet from '../assets/references/kozparktablet.png'
import kripteus from '../assets/references/kripteusmockup.webp'
import kripteusTablet from '../assets/references/kripteustablet.png'
import css from '../assets/references/cssmockup.png'
import cssTablet from '../assets/references/csstablet.png'
import himzoprogramTablet from '../assets/references/himzoprogramtablet.webp'
import himzoprogram from '../assets/references/himzoprogrammockup.webp'
import investhouse from '../assets/references/investmockup.webp'
import investhouseTablet from '../assets/references/investtablet.png'
import doterra from '../assets/references/doterramockup.webp'
import doterraTablet from '../assets/references/doterratablet.png'
import nyugdij from '../assets/references/nyugdij.jpg'
import nyugdijTablet from '../assets/references/nyugdijmindenkinek_mockup.png'
import superfly from '../assets/references/superfly.jpg'
import superflyTablet from '../assets/references/superfly-dark_mockup.png'
import rmami from '../assets/references/rozsavolgyi.jpg'
import rmamiTablet from '../assets/references/rozsavolgyi-dark_mockup.png'
import tcad from '../assets/references/tcad.jpg'
import tcadTablet from '../assets/references/tcad-dark_mockup.png'

export const mockups = [
  {
    id: 14,
    img: superfly,
    thumb: superflyTablet,
    altTag: 'Superfly Budapest',
    desc: 'Az Evolut Marketing Group Kft. megbízásából az Evolut Marketing Group Kft. egyedi designja alapján, a Superfly számára készített egyedi fejlesztésű weboldal. React, Strapi CMS.',
    link: 'https://superflybudapest.hu/',
  },
  {
    id: 13,
    img: tcad,
    thumb: tcadTablet,
    altTag: 'T-CAD Stúdió',
    desc: 'AT-CAD Stúdió mérnöki iroda számára készített egyedi fejlesztésű weboldal. React keretrendszer.',
    link: 'https://tcad.hu/',
  },
  {
    id: 12,
    img: rmami,
    thumb: rmamiTablet,
    altTag: 'Rózsavölgyi Márk Zeneiskola',
    desc: 'A Rózsavölgyi Márk Alapfokú Művészeti Iskola számára készített egyedi fejlesztésű weboldal. Astro, Strapi CMS.',
    link: 'https://rozsavolgyimark.hu/',
  },
  {
    id: 11,
    img: nyugdij,
    thumb: nyugdijTablet,
    altTag: 'Nyugdíjmegtakarítás tanácsadói oldal',
    desc: 'Egyedileg fejlesztésű (Astro) oldal.',
    link: 'https://www.nyugdijmindenkinek.hu/',
  },
  {
    id: 1,
    img: cooper,
    thumb: cooperTablet,
    altTag: 'Cooper Klima',
    desc: 'A C&H-Klima s.r.o. ausztriai fiókvállalata számára készített, egyedi fejlesztésű (React) tájékoztató (katalógus) oldal. Egyedi design és logók, melyek illeszkednek a cég arculatába. PWA, azaz progresszív web app, mobiltelefonra, desktop készülékekre is letölthető.',
    link: 'https://cooperklima.at',
  },
  {
    id: 2,
    img: lpo,
    thumb: lpoTablet,
    altTag: 'Landing Page Optimalizáció',
    desc: 'A The Impulse Kft. megbízásából az Evolut Marketing Group Kft. egyedi designja alapján, számukra készített egyedi fejlesztésű weboldal. Az oldal készítésében 3 fős csapat vett részt, melyből 2 fő a Digital Creative fejlesztője volt.',
    link: 'https://lpo.evolutagency.com',
  },
  {
    id: 3,
    img: ppc,
    thumb: ppcTablet,
    altTag: 'PPC',
    desc: 'A The Impulse Kft. megbízásából az Evolut Marketing Group Kft. egyedi designja alapján, számukra készített egyedi fejlesztésű weboldal. Az oldal készítésében 3 fős csapat vett részt, melyből 2 fő a Digital Creative fejlesztője volt.',
    link: 'https://ppc.evolutagency.com',
  },
  {
    id: 4,
    img: dev,
    thumb: devTablet,
    altTag: 'Dev Landing',
    desc: 'A The Impulse Kft. megbízásából az Evolut Marketing Group Kft. egyedi designja alapján, számukra készített egyedi fejlesztésű weboldal. Az oldal készítésében 3 fős csapat vett részt, melyből 2 fő a Digital Creative fejlesztője volt.',
    link: 'https://dev.evolutagency.com',
  },
  {
    id: 5,
    img: kozpark,
    thumb: kozparkTablet,
    altTag: 'Kispest Közpark',
    desc: 'A The Impulse Kft. megbízásából az Kispest Közpark Nonprofit Kft. részére készített egyedi fejlesztésű, egyedi design terv alapján készített weboldal. Az oldal készítésében 3 fős csapat vett részt, melyből 2 fő a Digital Creative fejlesztője volt.',
    link: 'https://kozpark.hu',
  },
  {
    id: 6,
    img: kripteus,
    thumb: kripteusTablet,
    altTag: 'Kripteus Lambda',
    desc: 'A The Impulse Kft. megbízásából az Evolut Marketing Group Kft. egyedi designja alapján, az Amadil Consulting Kft. számára készített egyedi fejlesztésű landing page. Az oldal készítésében 3 fős csapat vett részt, melyből 2 fő a Digital Creative fejlesztője volt.',
    link: 'https://lambda.kripteus.com',
  },
  {
    id: 7,
    img: css,
    thumb: cssTablet,
    altTag: 'CSS Landing',
    desc: 'A The Impulse Kft. megbízásából az Evolut Marketing Group Kft. egyedi designja alapján, számukra készített egyedi fejlesztésű landing oldal. Az oldal fejlesztését a Digital Creative 2 fejlesztője végezte.',
    link: 'https://css.evolutagency.com',
  },
  {
    id: 8,
    img: himzoprogram,
    thumb: himzoprogramTablet,
    altTag: 'Hímzőprogram készítés',
    desc: 'Az oldal olyan vállalkozásoknak nyújt teljes körű megoldást, akik rendelkeznek hímzőgéppel, ismerik a gépi hímzés technológiáját, de nem szeretnének a programozás rejtelmeiben elveszni. Oldal készítése során használt technológia: Astro/React',
    link: 'https://himzoprogram.hu',
  },
  {
    id: 9,
    img: investhouse,
    thumb: investhouseTablet,
    altTag: 'InvestHouse Vadvirág Resort',
    desc: 'Az Evolut Marketing Group Kft. megbízásából az Evolut Marketing Group Kft. egyedi designja alapján, az InvestHouse Kft. számára készített egyedi fejlesztésű landing page.',
    link: '',
  },
  {
    id: 10,
    img: doterra,
    thumb: doterraTablet,
    altTag: 'DoTerra tanácsadói oldal',
    desc: 'Grafikusunk által készített design alapján, egyedileg fejlesztett (Astro) landing oldal.',
    link: 'https://www.pureoil.at/',
  },
]
