import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Parser from 'html-react-parser'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { base_url } from '../utils/urls'
import { NavLink } from 'react-router-dom'
import Loader from '../utils/Loader'

import category from '../assets/icons/category.png'
import author from '../assets/icons/author.png'
import clock from '../assets/icons/clock.png'

const Blogs = () => {
  const page = useParams().id
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [metaImage, setMetaImage] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${base_url}/api/blogs?filters[slug]=${page}&populate=*`
        )
        setTitle(response.data.data[0].attributes.Title)
        setSubTitle(response.data.data[0].attributes.Subtitle)
        setMetaImage(
          response.data.data[0].attributes.Coverimage.data.attributes.url
        )
        setData(response.data.data)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }
    fetchData()
  }, [])

  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content='Digital Creative - Blog' />
        <title>Digital Creative - {title}</title>
        <meta property='og:title' key='og:title' content={title} />
        <meta
          property='og:description'
          key='og:description'
          content={subTitle}
        />
        <meta property='og:image' key='og:image' content={metaImage} />
      </Helmet>
      <section>
        {!isLoading ? (
          <>
            {data.map((item, i) => {
              return (
                <div className='post-container' key={item.id}>
                  <div className='post-details'>
                    <div className='post-details-item'>
                      <img src={clock} alt='clock' />
                      <p className='post-date'>
                        <span>Dátum:</span>
                        {new Date(
                          item.attributes.publishedAt
                        ).toLocaleDateString('hu-HU')}
                      </p>
                    </div>
                    <div className='post-details-item'>
                      <img src={author} alt='author' />
                      <span>Szerző:</span>
                      <p className='post-author'>{item.attributes.Author}</p>
                    </div>
                    <div className='post-details-item'>
                      <img src={category} alt='category' />
                      <span>Kategória:</span>
                      <p className='post-category'>
                        {item.attributes.Category}
                      </p>
                    </div>
                  </div>
                  <div className='post-image-container'>
                    <img
                      src={item.attributes.Coverimage.data.attributes.url}
                      alt={
                        item.attributes.Coverimage.data.attributes
                          .alternativeText
                      }
                    />
                  </div>
                  <div className='post-content-container'>
                    <div className='post-content'>
                      {Parser(item.attributes.Content)}
                    </div>
                  </div>
                  <div className='back'>
                    <NavLink to={'/blog'}>
                      <button className='btn'>Vissza a blogcikkekhez</button>
                    </NavLink>
                  </div>
                </div>
              )
            })}
          </>
        ) : (
          <Loader />
        )}
      </section>
    </Wrapper>
  )
}

export default Blogs

const Wrapper = styled.main`
  .post-container {
    .post-details {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &-item {
        display: flex;
        align-items: center;
        padding: 3rem;

        @media screen and (max-width: 1024px) {
          flex-direction: column;
          justify-content: center;
          padding: 1.5rem;

          .post-date {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        @media screen and (max-width: 768px) {
          padding: 0.8rem 0;
          font-size: 0.875rem;

          .post-date,
          .post-category,
          .post-author {
            font-size: 0.875rem;
          }
        }

        span {
          font-weight: 700;
          margin-right: 0.2rem;
        }

        img {
          height: 2rem;
          margin-right: 1rem;

          @media screen and (max-width: 1024px) {
            margin-right: 0;
            height: 1.2rem;
          }
        }
      }
    }
    .post-image-container img {
      width: 100%;
    }

    .back {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
    }

    .post-content-container {
      margin: 0 5rem 2rem 5rem;
      line-height: 1.6;

      @media screen and (max-width: 1024px) {
        margin: 0 0 2rem 0;
      }

      @media screen and (max-width: 768px) {
        font-size: 0.875rem;

        p {
          font-size: 0.875rem;
        }
      }

      .post-content {
        margin-top: 2rem;

        @media screen and (max-width: 1024px) {
          align-item: center;
        }
        p {
          color: var(--text-color);
          padding: 0.8rem 0;

          @media screen and (max-width: 768px) {
            padding: 0.3rem;
          }
          strong {
            background-color: var(--main-gray);
            font-size: clamp(1rem, 1.255rem + 0.4vw, 1.5rem);
            font-weight: 700;
            display: block;
            padding: 1rem 0 1rem 1rem;
            margin-bottom: 0.5rem;
          }
          i::before {
            content: '';
            position: absolute;
            width: 5px;
            height: 60px;
            background-color: var(--secondary-red);
            margin-left: -1rem;
          }
          a {
            color: var(--link-blue);
            text-decoration: none;
          }
        }
        ul,
        ol {
          padding-left: 3rem;
          margin: 1rem 0;
        }
      }
    }
  }
`
