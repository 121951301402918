import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import { Navbar, Footer } from './components'
import {
  Main,
  AboutUs,
  Services,
  CustomServices,
  Blog,
  Blogs,
  ContactUs,
  Error,
  Cookies,
  Impressum,
  Documents,
  Gdpr,
  VideoServices,
  GraphicsServices,
  WebsiteServices,
  SocialServices,
  SeoServices,
} from './pages'
import FloatingBtn from './components/FloatingBtn'
import ScrollToTop from './utils/ScrollToTop'
import CookieConsent from 'react-cookie-consent'
import { GlobalContext } from './utils/GlobalContext'
import { HelmetProvider } from 'react-helmet-async'
import ReactGA from 'react-ga4'

ReactGA.initialize('G-PBHNC7V0LJ')

function App() {
  useEffect(() => {
    ReactGA.send('pageview')
  }, [])

  const [navClicked, setNavClicked] = useState(false)
  const clickValue = {
    navClicked,
    setNavClicked,
  }
  // eslint-disable-next-line
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  smoothscroll.polyfill()

  return (
    <>
      <HelmetProvider>
        <Router>
          <GlobalContext.Provider value={clickValue}>
            <Navbar />
            <FloatingBtn />
            <ScrollToTop>
              <Routes>
                <Route path='/' element={<Main />} />
                <Route path='/rolunk' element={<AboutUs />} />
                <Route path='/szolgaltatasok' element={<Services />} />
                <Route
                  path='/szolgaltatasok/egyedi-fejlesztes'
                  element={<CustomServices />}
                />
                <Route
                  path='/szolgaltatasok/weboldal-keszites'
                  element={<WebsiteServices />}
                />
                <Route
                  path='/szolgaltatasok/grafikai-munkak'
                  element={<GraphicsServices />}
                />
                <Route
                  path='/szolgaltatasok/social-media'
                  element={<SocialServices />}
                />
                <Route
                  path='/szolgaltatasok/video-spotok'
                  element={<VideoServices />}
                />
                <Route
                  path='/szolgaltatasok/technikai-seo'
                  element={<SeoServices />}
                />
                <Route path='/blog' element={<Blog />} />
                <Route path='/blog/:id' element={<Blogs />} />
                <Route path='/letoltesek' element={<Documents />} />
                <Route path='/kapcsolat' element={<ContactUs />} />
                <Route path='/sutik' element={<Cookies />} />
                <Route path='/impresszum' element={<Impressum />} />
                <Route path='/gdpr' element={<Gdpr />} />
                <Route path='*' element={<Error />} />
                <Route path='/404' element={<Error />} />
              </Routes>
            </ScrollToTop>
            <CookieConsent
              location='bottom'
              buttonText='Megértettem'
              cookieName='myAwesomeCookieName2'
              style={{ background: '#e23037' }}
              buttonStyle={{
                background: '#111111',
                color: '#fff',
                fontSize: '16px',
              }}
              expires={150}>
              Ez a weboldal cookie-kat használ annak biztosítására, hogy a
              lehető legjobb élményt nyújthassuk honlapunkon.
              <span>
                <NavLink
                  style={{
                    fontSize: '16px',
                    color: 'white',
                    marginLeft: '10px',
                  }}
                  to='/sutik'>
                  További tudnivalók
                </NavLink>
              </span>
            </CookieConsent>
            <Footer />
          </GlobalContext.Provider>
        </Router>
      </HelmetProvider>
    </>
  )
}

export default App
