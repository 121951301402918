import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'

import bg from '../assets/images/seo-bg.webp'
import seo from '../assets/images/seo.webp'
import right from '../assets/icons/right.png'

const SeoServices = () => {
  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content='Digital Creative - Technikai SEO' />
        <title>Digital Creative - Technikai SEO</title>
      </Helmet>
      <div>
        <div className='bg-container'></div>
        <section className='seo-container'>
          <div className='seo-image'>
            <img src={seo} alt='seo' />
          </div>
          <div className='seo-text'>
            <h2>
              <span className='red'>S</span>earch <span className='red'>E</span>
              ngine <span className='red'>O</span>ptimalization
            </h2>
            <p>
              <strong>Mindenki</strong> mindent szinte azonnal szeretne, ezzel
              partnereink sincsenek másként. Találkozott már lassan töltödő,
              akadozó weboldallal? Megvárta míg betöldődött? Vagy menet közben
              inkább elkattintott? Egy weboldal hiába szép, hiába stílusos, ha
              lassú és nincs megfelelően optimalizálva. Arról már nem is
              beszélve hogy a google keresője a lassabb oldalakat hátrébb
              sorolja a találati listán, lényegében "büntetve" azokat.
            </p>
            <p>
              <strong>A legmodernebb</strong> webes technológiákat alkalmazzuk,
              így garantálva megrendelőinknek hogy az oldal felépítése,
              tervezése folytán jól kereshető, SEO alapelveknek megfelelő lesz.
              A keresőbarát weboldal alap, nem egy extra szolgáltatás. Az
              oldalak készítése során mindig figyelembe vesszük az aktuális SEO
              irányelveket, melyek megfelelő működését az oldal elkészülte után
              teszteljük.
            </p>
          </div>
        </section>
        <section>
          <div className='wantmore'>
            <section className='wantmore-container'>
              <div className='btn-container'>
                <NavLink to='/szolgaltatasok'>
                  <button className='btn'>Vissza a szolgáltatásokhoz</button>
                </NavLink>
              </div>
              <NavLink to={`/kapcsolat`}>
                <div className='wantmore-wrapper'>
                  <img src={right} alt='right arrow' />
                  <p className='bold'>Szeretnék egy profi weboldalt</p>
                </div>
              </NavLink>
            </section>
          </div>
        </section>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  .bg-container {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: center;
    height: calc(50vh - 95px);
    width: 100%;
    background-size: cover;

    @media screen and (max-width: 1024px) {
      height: 30vh;
    }
  }

  .seo-image {
    display: flex;
    order: 2;

    img {
      height: 550px;

      @media screen and (max-width: 1024px) {
        width: 100%;
        height: auto;
      }
    }
  }

  .seo-container {
    margin-top: 5rem;
    display: flex;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .seo-text {
      margin-left: 4rem;
      order: 2;

      @media screen and (max-width: 1024px) {
        margin-top: 1rem;
        margin-bottom: 2rem;
        margin-left: 0;
        order: 1;
      }
    }

    h2 {
      position: relative;
      padding-left: 2rem;

      @media screen and (max-width: 1024px) {
        width: 100%;
        padding-left: 0;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-left: 0;

        ::before {
          display: none;
        }

        ::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -5px;
          width: 20%;
          height: 5px;
          background-color: var(--secondary-red);
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 100%;
        background-color: var(--secondary-red);
      }
    }

    p {
      margin-top: 2rem;
    }
  }

  .wantmore {
    padding: 0 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem 0 3rem 0;
    width: 100%;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      justify-content: center;
      padding: 0;
      width: 100%;
      justify-content: space-around;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 2rem 0 0 0;
      justify-content: center;
      width: 100%;
    }

    .wantmore-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    .btn-container {
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        order: 2;
      }
    }

    a {
      text-decoration: none;
      color: var(--text-color);
    }
    .wantmore-wrapper {
      display: flex;
      align-items: center;
      transition: all 0.3s ease;

      @media screen and (max-width: 768px) {
        order: 1;
      }
      &:hover {
        transform: scale(1.1);
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
      }

      p {
        font-size: 1.25rem;
        margin: 0;

        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }
    }
  }
`

export default SeoServices
