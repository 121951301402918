import React from 'react'

import styled from 'styled-components'
import arrow from '../assets/icons/up-arrow.svg'

const FloatingBtn = () => {
  const floatingBtn = window.scrollY > 250
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  return (
    <Wrapper>
      {floatingBtn && (
        <>
          <div className='floating-btn' onClick={scrollToTop}>
            <img src={arrow} alt='arrow' />
          </div>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .floating-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: var(--secondary-red);
    z-index: 100;

    @media screen and (max-width: 1024px) {
      width: 50px;
      height: 50px;
    }

    img {
      width: 50%;
    }
  }

  .floating-btn:hover {
    cursor: pointer;
  }
`
export default FloatingBtn
