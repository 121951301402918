import easy from '../assets/logos/easyfeherfull.png'
import yeti from '../assets/logos/yeti.png'
import petBar from '../assets/logos/petbar.png'
import berenyi from '../assets/logos/berenyi.png'
import mille from '../assets/logos/mille.png'
import seagull from '../assets/logos/seagull.png'
import topCycles from '../assets/logos/topcycles.png'
import step from '../assets/logos/step.png'
import cooperklima from '../assets/logos/cooperklima.png'

export const logos = [
  { id: 1, img: easy, altTag: 'Easylot logo' },
  { id: 2, img: yeti, altTag: 'Yeti Shop logo' },
  { id: 3, img: petBar, altTag: 'PetBar logo' },
  { id: 4, img: berenyi, altTag: 'Berenyi logo' },
  { id: 5, img: mille, altTag: 'Mille logo' },
  { id: 6, img: seagull, altTag: 'Seagull logo' },
  { id: 7, img: topCycles, altTag: 'Top Cycles logo' },
  { id: 8, img: step, altTag: 'Step by Step logo' },
  { id: 9, img: cooperklima, altTag: 'Cooper-Klíma Kft. logo' }
]
