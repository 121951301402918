import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'
import BlogCard from '../components/BlogCard'
import Loader from '../utils/Loader'

import bg from '../assets/images/blog-cover.webp'

// const baseUrl = 'localhost:1337/api/blogs'
const baseUrl = 'https://dcreative-blog.herokuapp.com/api/blogs'

const Blog = () => {
  const [posts, setPosts] = useState([])
  const [newPosts, setNewPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [noMoreData, setNoMoreData] = useState(false)

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          //`${baseUrl}?sort=publishedAt%3Adesc&pagination[pageSize]=6&populate=*`
          `${baseUrl}?sort=Created%3Adesc&pagination[pageSize]=6&populate=*`
        )
        setPosts(response.data.data)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    fetchBlog()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}?sort=publishedAt%3Adesc&pagination[pageSize]=6&pagination[page]=${currentPage}&populate=*`
        )
        setNewPosts(response.data.data)
      } catch (error) {
        console.log(error)
      }
      try {
        const response = await axios.get(
          `${baseUrl}?sort=publishedAt%3Adesc&pagination[pageSize]=6&pagination[page]=${
            currentPage + 1
          }&populate=*`
        )
        if (response.data.data.length === 0) {
          setNoMoreData(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [currentPage])

  useEffect(() => {
    if (currentPage !== 1) {
      setPosts((oldArray) => [...oldArray, ...newPosts])
    }
    //eslint-disable-next-line
  }, [newPosts])

  return (
    <MainWrapper>
      <Helmet>
        <meta name='description' content='Digital Creative - Blog' />
        <title>Digital Creative - Blog</title>
      </Helmet>
      <div className='page-hero'></div>
      <div className='blog-header'>
        <div className='blog-header-content'>
          <div className='title'>
            <h2>Írások, vélemények, gondolatok</h2>
            <p>
              Hasznos tippek, érdekességek, aktuális trendek az online világból!
            </p>
          </div>
        </div>
      </div>
      <section>
        <div className='blog-collector'>
          {!loading ? (
            <>
              {posts.map((post, index) => (
                <BlogCard key={index} post={post} />
              ))}
            </>
          ) : (
            <Loader />
          )}
        </div>
        {noMoreData ? (
          <div className='btn-container no-post'>
            <h2>Nincsenek további cikkek.</h2>
          </div>
        ) : (
          <div className='btn-container'>
            <button
              className='btn'
              onClick={() => setCurrentPage(currentPage + 1)}>
              További cikkek betöltése
            </button>
          </div>
        )}
      </section>
    </MainWrapper>
  )
}

export default Blog

const MainWrapper = styled.main`
  .page-hero {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.25)
      ),
      url(${bg});
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: center;
    height: calc(71vh - 95px);
    width: 100%;
    background-size: cover;

    @media screen and (max-width: 1024px) {
      background-position-y: center;
      background-position-x: center;
      height: 50vh;
    }
  }

  .blog-header {
    width: 100%;
    background-color: var(--main-gray);

    .blog-header-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin: auto;
      width: 1140px;
      max-width: 90%;

      @media screen and (max-width: 480px) {
        flex-direction: column;
      }

      .title {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 53%;
        justify-content: flex-start;
        margin-block: 3.5rem;

        p {
          margin-top: 1rem;
        }

        ::before {
          position: absolute;
          content: '';
          top: -5%;
          left: -50px;
          width: 5px;
          height: 110%;
          background-color: var(--secondary-red);
        }

        @media screen and (max-width: 480px) {
          width: 100%;
          ::before {
            display: none;
          }
          ::after {
            position: absolute;
            content: '';
            bottom: -15px;
            left: 0;
            width: 20%;
            height: 5px;
            background-color: var(--secondary-red);
          }
        }
      }
    }
  }

  .blog-collector {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: var(--secondary-bg);
    min-height: 40vh;
    margin-block: 2rem;

    @media screen and (max-width: 1024px) {
      justify-content: center;
    }
  }

  .no-post h2 {
    position: relative;

    ::after {
      position: absolute;
      content: '';
      bottom: -5px;
      left: 0;
      height: 5px;
      width: 20%;
      background-color: var(--secondary-red);
    }
  }
`
