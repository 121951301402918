import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'

import bg from '../assets/images/404.webp'

const Error = () => {
  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content='404' />
        <title>404</title>
      </Helmet>
      <div className='error-container'>
        <div className='content-wrapper'>
          <div className='text-content'>
            <h2>404</h2>
            <h1 className='hero-title'>
              Hoppá! Ez biztos nem a keresett oldal!
            </h1>
            <Link to='/' className='btn'>
              Vissza a főoldalra
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Error

const Wrapper = styled.main`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-image: url(${bg});

  .text-content h2 {
    font-size: var(--headline-font-size);
    color: var(--secondary-bg);
    font-weight: bold;
  }

  .error-container {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.4)
      ),
      url(${bg});
    background-repeat: no-repeat;
    background-position-x: 80%;
    background-position-y: center;
    height: 100vh;
    width: 100%;
    background-size: cover;

    @media screen and (max-width: 1024px) {
      height: 100vh;
    }

    .content-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      align-items: flex-start;
      justify-content: center;
      text-align: center;
      padding-top: 5rem;

      h2 {
        font-size: 10rem;
      }
    }

    .hero-title {
      color: var(--secondary-bg);
      line-height: 1.2;
      width: 80%;
      margin: auto;
      padding-bottom: 5rem;
      padding-top: 1rem;
      font-size: 2rem;
      font-family: var(--basic-font-family);
      font-weight: 400;
    }
    .btn {
      border: 3px solid #fff;
      color: white;
      font-size: var(--button-font-size);
      width: 50%;
      text-decoration: none;
      text-align: center;
      transition: all 0.3s ease-in-out;
      border-radius: 0;

      background-color: transparent;
    }
    .btn:hover {
      color: var(--text-color);
      background-color: var(--secondary-bg);
    }

    .bg-image {
      padding-right: -15%;
    }
    @media (max-width: 1024px) {
      .content-wrapper {
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 2rem;
      }
    }
  }
`
