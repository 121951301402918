import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

import netlify from '../assets/logos/netlify.png';

const Impressum = () => {
  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content='Digital Creative - Impresszum' />
        <title>Digital Creative - Impresszum</title>
      </Helmet>
      <div className='content-wrapper'>
        <div className='hero-title'>
          <h1>Impreszum</h1>
        </div>
        <div className='hosting'>
          <h2>Tárhelyszolgáltató:</h2>
          <h3>Netlify Inc.</h3>
          <picture>
            <img className='logo' src={netlify} alt='Netlify logo' />
          </picture>
          <div>
            <h3>Székhely:</h3>
            <p>610 22nd St #315, San Francisco</p>
            <h3>Levelezési cím:</h3>
            <p>610 22nd St #315, San Francisco</p>
            <h3>Email:</h3>
            <p>support@netlify.com</p>
          </div>
        </div>

        <div className='owner'>
          <h2>Weboldal üzemeltetője:</h2>
          <h4>Digital Creative Agency Kft.</h4>
          <div>
            <h3>Székhely/levelezési cím:</h3>
            <p>2660 Balassagyarmat, Patvarci utca 18.</p>
            <h3>Bejegyző hatóság:</h3>
            <p>Balassagyarmati Törvényszék Cégbírósága</p>
            <h3>Adószám:</h3>
            <p>32083475-2-12</p>
            <h3>EU adószám:</h3>
            <p>HU32083475</p>
            <h3>Cégjegyzékszám:</h3>
            <p>12-09-012159</p>
            <h3>Telefonszám:</h3>
            <p>+36(70)530-1130</p>
            <h3>Email:</h3>
            <p>info@digitalcreative.hu</p>
            <h3>Iparkamara:</h3>
            <p>Nógrád Megyei Kereskedelmi és Iparkamara</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 150px;
    width: 1140px;
    height: 100%;
    max-width: 90%;
    margin: auto;
    padding-bottom: 5rem;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .main-title {
    margin-bottom: 2rem;

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  h1 {
    display: inline-block;
    font-size: var(--main-title-font-size);
    position: relative;
    margin-bottom: 2rem;
  }

  h1::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 5px;
    background-color: var(--text-color);
  }

  .hosting img {
    width: 80px;
  }

  .hosting h2,
  .owner h2 {
    margin: 1rem 0;
    position: relative;
    padding-left: 10px;
  }

  .owner h2 {
    margin-top: 2rem;
  }

  .hosting h4,
  .owner h4 {
    margin-block: 0.5rem;
    font-size: calc(var(--subtitle-font-size) + 0.2rem);
    font-weight: 400;

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  img {
    height: 60px;
  }

  h3 {
    margin: 1rem 0 0.3rem;
    position: relative;
    padding-left: 20px;
    font-size: var(--subtitle-font-size);
  }
`;

export default Impressum;
