import React from 'react'
import styled from 'styled-components'

import loading from '../assets/icons/solutions.png'

const Loader = () => {
  return (
    <Wrapper>
      <div className='loader-container'>
        <img src={loading} alt='loader icon' />
      </div>
    </Wrapper>
  )
}

export default Loader

const Wrapper = styled.section`
  display: flex;

  min-height: 50vh;
  align-items: center;
  justify-content: center;

  .loader-container {
    width: 10%;
    height: 10%;
    animation: load 3s ease infinite;

    img {
      width: 100%;
      height: auto;
    }
  }

  @keyframes load {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
