import customDev from '../assets/icons/custom-development-red.svg'
import websiteDev from '../assets/icons/website-development-red.svg'
import seo from '../assets/icons/seo-red.svg'
import video from '../assets/icons/video-red.svg'

export const services = [
  {
    id: 1,
    img: customDev,
    link: '/szolgaltatasok/egyedi-fejlesztes',
    title: 'Egyedi weboldal',
  },
  {
    id: 2,
    img: websiteDev,
    link: '/szolgaltatasok/weboldal-keszites',
    title: 'Wordpress weboldal',
  },
  {
    id: 3,
    img: video,
    link: '/szolgaltatasok/video-spotok',
    title: 'Video Spotok',
  },
  {
    id: 4,
    img: seo,
    link: '/szolgaltatasok/technikai-seo',
    title: 'Technikai Seo',
  },
]
