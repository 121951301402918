import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import contact from '../assets/images/contact.png'
import email from '../assets/icons/email.png'
import phone from '../assets/icons/phone.png'

const ContactUs = () => {
  return (
    <MainWrapper>
      <Helmet>
        <meta name='description' content='Digital Creative - Kapcsolat' />
        <title>Digital Creative - Kapcsolat</title>
      </Helmet>
      <section>
        <div className='form-wrapper'>
          <div className='info-container'>
            <div className='contact-img'>
              <img src={contact} alt='contact logo' />
            </div>
            <div className='contact-text'>
              <p>
                Vegye fel velünk a kapcsolatot elérhetőségeink bármelyikén!
                Mindig nyitottak vagyunk új projektek, kreatív ötletek vagy
                aktuális kihívások megvalósítására.
              </p>
              <p className='bold'>Keressen minket bizalommal!</p>
            </div>
            <div className='contact-datas'>
              <div className='contact-datas-img'>
                <img src={email} alt='mail logo' />
                <a href='mailto:info@digitalcreative.hu'>
                  info@digitalcreative.hu
                </a>
              </div>
              <div className='contact-datas-img'>
                <img src={phone} alt='mail logo' />
                <div>
                  <p>Majoros Tamás - webfejlesztés</p>
                  <a href='tel:06705301130'>+36-70/530-1130</a>
                </div>
              </div>
            </div>
          </div>
          <div className='form-container'>
            <div className='title-container'>
              <h2>
                Lépjen <span className='red'>kapcsolatba</span> velünk!
              </h2>
            </div>
            <form
              name='contact'
              method='post'
              data-netlify-recaptcha='true'
              data-netlify='true'
              onSubmit='submit'
              netlify-honeypot='bot-field'
              action='/success'>
              <input type='hidden' name='form-name' value='contact' />
              <input type='text' placeholder='Név' name='name' required />
              <input type='email' placeholder='Email' name='email' required />

              <textarea
                name='message'
                rows='15'
                placeholder='Üzenet'
                required></textarea>
              <div className='check'>
                <input id='chkbox' type='checkbox' className='chbox' required />
                <label htmlFor='chkbox' className='gdpr-warning'>
                  Elfogadom az <Link to='/gdpr'>adatkezelési feltételeket</Link>
                </label>
              </div>

              <div>
                <button type='submit' className='btn'>
                  üzenet küldése
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </MainWrapper>
  )
}

export default ContactUs

const MainWrapper = styled.main`
  background-color: var(--main-bg);
  color: var(--secondary-bg);
  width: 100%;
  height: 100%;

  .form-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-block: 80px;
    gap: 2rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .info-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 28%;
      min-height: 50vh;

      @media screen and (max-width: 768px) {
        width: 100%;
        min-height: 30vh;
      }

      .contact-img {
        max-width: 180px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .contact-text {
        font-size: var(--basic-font-size);
        margin-block: 2rem;
      }

      .contact-datas {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .contact-datas-img {
          display: flex;
          align-items: center;

          img {
            height: 3rem;
          }

          p {
            margin-bottom: 5px;
          }

          a {
            text-decoration: none;
            color: var(--secondary-bg);
            margin-left: 5px;

            :hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 65%;
      min-height: 50vh;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .title-container {
        margin-bottom: 3rem;
      }

      form {
        display: flex;
        width: 100%;
        flex-direction: column;

        input {
          height: 50px;
        }

        input,
        textarea {
          padding: 10px 12px;
          margin-bottom: 1.2rem;
          color: var(--secondary-bg);
          font-size: var(--basic-font-size);
          outline: none;
          border: 2px solid var(--secondary-red);
          background-color: var(--main-bg);

          :focus,
          :active {
            outline: none;
            border: 2px solid var(--main-bg);
            background: linear-gradient(90deg, white 50%, transparent 50%),
              linear-gradient(90deg, white 50%, transparent 50%),
              linear-gradient(0deg, white 50%, transparent 50%),
              linear-gradient(0deg, white 50%, transparent 50%);
            background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
            background-size: 16px 2px, 16px 2px, 2px 16px, 2px 16px;
            background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
            animation: dash 8s linear infinite;
          }
        }

        label {
          margin-left: 15px;
        }

        .check {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          margin: 20px 0;
        }

        .chbox {
          margin-top: 20px;
        }

        .gdpr-warning a {
          color: #478ac9;
          text-decoration: none;

          :hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  h1 {
    color: white;
  }

  @keyframes dash {
    to {
      background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
    }
  }
`
