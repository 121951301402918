import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'

import bg from '../assets/images/download-bg.png'
import download from '../assets/icons/download.png'
import pin from '../assets/icons/pin.svg'
import webBrief from '../assets/documents/weboldal_brief.docx'
import graphicBrief from '../assets/documents/grafika_brief.docx'
import domainPDF from '../assets/documents/mi_az_a_domain.pdf'
import uzletiPDF from '../assets/documents/uzleti_tajekoztato.pdf'
import arculatPDF from '../assets/documents/Digital Creative arculati kézikönyv.pdf'

const Documents = () => {
  const step = 1
  const interval = 10
  const max = 100

  const [number, setNumber] = useState(0)

  useEffect(() => {
    const updateProgress = () => setNumber(number + step)
    if (number < max) {
      setTimeout(updateProgress, interval)
    }

    if (number === 100) {
      return clearTimeout(updateProgress)
    }
  }, [number])

  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content='Digital Creative - Dokumentumok' />
        <title>Digital Creative - letölthető dokumentumok, tájékoztatók</title>
      </Helmet>
      <div className='main-wrapper'>
        <div className='download-bg'></div>

        <section className='download-container'>
          <div className='download-container-first'>
            {/* <h2>{number} %</h2> */}
            <h1>Letölthető dokumentumok, tájékoztatók</h1>
            <p>
              Minden vállalkozás számára elengedhetetlen az online jelenlét
              valamilyen formában. Segítséget szeretnénk nyújtani Önnek abban,
              hogy vállalkozása minél hatékonyabban ki tudja aknázni az online
              piacban rejlő lehetőségeket, így ingyenesen letölthető tartalmakat
              készítettünk különböző témákban.
            </p>
          </div>

          <div className='download-content'>
            <h3>Letölthető brief-ek</h3>
            <div className='download-content-brief'>
              <p className='download-content-brief-text'>
                Amennyiben van elképzelése, hogy milyen weboldalt szeretne,
                kérjük töltse le és kitöltve küldje vissza az{' '}
                <a href="mailto:info@digitalcreative.hu'">
                  info@digitalcreative.hu&nbsp;
                </a>
                email címre és kollégánk hamarosan felkeresi Önt.
              </p>
              <div className='download-content-brief-icons'>
                <a href={webBrief} rel='noreferrer noopener' target='blank'>
                  <img src={download} alt='download-icon' />
                  Weblap brief
                </a>
                <a href={graphicBrief} rel='noreferrer noopener' target='blank'>
                  <img src={download} alt='download-icon' />
                  Grafika brief
                </a>
              </div>
            </div>
            <h3>Hasznos és érdekes tartalmak</h3>
            <div className='download-content-brief'>
              <p className='download-content-brief-text'>
                <span>
                  <img src={pin} alt='pin icon' />
                  Üzleti tájékoztató
                </span>
              </p>
              <div className='download-content-brief-icons'>
                <a href={uzletiPDF} rel='noreferrer noopener' target='blank'>
                  <img src={download} alt='download-icon' />
                  Érdekel, letöltöm!
                </a>
              </div>
            </div>
            <div className='download-content-brief'>
              <p className='download-content-brief-text'>
                <span>
                  <img src={pin} alt='pin icon' />
                  Mi az a domain név?
                </span>
              </p>
              <div className='download-content-brief-icons'>
                <a href={domainPDF} rel='noreferrer noopener' target='blank'>
                  <img src={download} alt='download-icon' />
                  Érdekel, letöltöm!
                </a>
              </div>
            </div>
            <div className='download-content-brief'>
              <p className='download-content-brief-text'>
                <span>
                  <img src={pin} alt='pin icon' />
                  Arculati kézikönyv
                </span>
              </p>
              <div className='download-content-brief-icons'>
                <a href={arculatPDF} rel='noreferrer noopener' target='blank'>
                  <img src={download} alt='download-icon' />
                  Érdekel, letöltöm!
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  )
}

export default Documents

const Wrapper = styled.main`
  .main-wrapper {
    position: relative;
    width: 100%;
  }

  .download-bg {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: center;
    height: calc(100vh - 45px);
    width: 100%;
    background-size: cover;
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: 0.3;

    @media screen and (min-width: 1300px) and (max-width: 1600px) {
      height: calc(130vh - 95px);
    }

    @media screen and (min-width: 820px) and (max-width: 1300px) {
      background-position: 50% 50%;
    }

    @media screen and (max-width: 820px) {
      background-image: none;
      height: 100%;
    }
  }
  .download-container {
    &-first {
      padding-top: 2rem;
    }

    @media screen and (max-width: 820px) {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: 480px) {
      margin-bottom: 3rem;
    }

    .download-container-first {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      h1 {
        font-size: clamp(2rem, 1.5714rem + 1.1905vw, 3rem);
        position: relative;
        width: fit-content;

        ::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -8px;
          width: 100%;
          height: 8px;
          background-color: var(--secondary-red);

          @media screen and (max-width: 820px) {
            width: 0;
          }
        }
      }
    }

    h2 {
      font-size: 8rem;
      font-weight: bold;
      font-family: var(--hero-font-family);
      position: relative;
      margin-bottom: 4rem;
      margin-left: 2rem;

      @media screen and (max-width: 820px) {
        font-size: 5rem;
        margin-top: 2rem;
        margin-left: 0;
      }

      ::after {
        content: '';
        position: absolute;
        left: -2rem;
        bottom: -5px;
        width: 20%;
        height: 8px;
        background-color: var(--secondary-red);

        @media screen and (max-width: 820px) {
          left: 0;
        }
      }
    }
    &-first {
      p {
        width: 50%;
        line-height: 1.6;
        @media screen and (max-width: 820px) {
          width: 100%;
        }
      }
    }

    img {
      height: 50px;
    }

    .download-content {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 0;
      margin-block: 3rem;
      font-weight: 600;

      h3 {
        margin-bottom: 1rem;
      }

      h3:not(:first-child) {
        margin-top: 2rem;
      }

      &-brief {
        display: flex;
        width: 100%;
        gap: 2rem;
        align-items: center;

        span {
          display: flex;
          width: 100%;
          align-items: center;
          gap: 1rem;

          img {
            width: 7%;
          }
        }

        @media screen and (max-width: 480px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 0;
        }

        &-text {
          width: 60%;
          @media screen and (max-width: 480px) {
            width: 100%;
          }
        }
        &-icons {
          width: 40%;
          @media screen and (max-width: 480px) {
            width: 100%;
          }

          a {
            padding-block: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5rem;
            width: fit-content;
          }
        }
      }

      @media screen and (max-width: 820px) {
        width: 100%;
        padding: 3rem 2rem;
        background: var(--text-gray);
      }

      a {
        align-items: center;
        text-decoration: none;
        color: var(--text-color);
        position: relative;

        ::before {
          position: absolute;
          content: '';
          bottom: 10px;
          left: 60px;
          width: 0;
          height: 4px;
          background-color: var(--secondary-red);
          transition: width 0.3s ease-in-out;
        }
        :hover {
          color: var(--secondary-red);

          ::before {
            width: calc(100% - 60px);
          }
        }
      }
    }
  }
`
