import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'

import { services } from '../utils/ServicesContainer'

import { partners } from '../utils/PartnersContainer'

import { Slider } from '../components'

import dc from '../assets/videos/dc-main.mp4'
import bg from '../assets/images/dc_hero.webp'
import business from '../assets/images/coder.webp'

const Main = () => {
  return (
    <Wrapper>
      <Helmet>
        <meta
          name='description'
          content='Digital Creative - innovatív digitális megoldások'
        />
        <meta
          property='og:title'
          key='og:title'
          content='Digital Creative - innovatív digitális megoldások'
        />
        <meta
          property='og:description'
          key='og:description'
          content='Csapatként dolgozunk együtt partnereinkkel. Ez teszi lehetővé  számunkra, hogy minél jobban megismerjük az ügyfeleink igényeit és a legjobb megoldásokat kínáljuk számukra.'
        />
        <meta property='og:image' key='og:image' content={bg} />
        <title>Digital Creative - Innovatív Digitális Megoldások</title>
      </Helmet>
      <div className='bg-container'>
        <div className='text-container'>
          <div className='left'>
            <h1>
              <span className='light'>Innovatív</span> Digitális megoldások
            </h1>
            <p>
              Csapatként dolgozunk együtt partnereinkkel. Ez teszi lehetővé
              számunkra, hogy minél jobban megismerjük az ügyfeleink igényeit és
              a legjobb megoldásokat kínáljuk számukra. Minden egyes projekten
              teljes erőbedobással dolgozunk, mert megbízóink sikere a mi
              sikerünk is.
            </p>
            <br />
            <p>
              A DIGITAL CREATIVE csapatára mindig minden körülmények között
              számíthat.
            </p>
            <NavLink to='/kapcsolat'>
              <button className='btn'>Érdekel</button>
            </NavLink>
          </div>
          <div className='right'></div>
        </div>
      </div>
      <section>
        <div className='content-container'>
          <div className='left-block'>
            <h2>
              Több vagyunk mint egy <span className='red'>webfejlesztő </span>
              csapat
            </h2>
            <div className='darkgray'>
              <p>
                Amikor a mi cégünkkel dolgozik tudni fogja, hogy nálunk
                nincsenek apróbetűk, nincsenek trükkök. Minden projektet
                személyre szabunk a legoptimálisabb kivitelezés és a
                költséghatékonyság érdekében.
              </p>
            </div>
            <p>
              Nem használunk sablon megoldásokat, a gyártási folyamat alapos
              tervezésen alapul, így a munkafázisok mindvégig kiszámíthatóak és
              átláthatók.
            </p>
            <p>
              Ennek eredményeként az Önnek elkészített munka minden esetben
              tükrözni fogja a DIGITAL CREATIVE csapat szellemiségét és
              maximalizmusát.
            </p>
            <div className='services-grid'>
              {services.map((service) => (
                <div className='icon-box' key={service.id}>
                  <NavLink to={service.link}>
                    <p className='bold'>{service.title}</p>
                    <img src={service.img} alt={service.title} />
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
          <div className='right-block'>
            <img src={business} alt='handsake' />
          </div>
        </div>
      </section>
      <video playsInline autoPlay muted loop className='spot'>
        <source src={dc} type='video/mp4'></source>
      </video>
      <Slider datas={partners} />
    </Wrapper>
  )
}

export default Main

const Wrapper = styled.main`
  .bg-container {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.45),
        rgba(0, 0, 0, 0.45)
      ),
      url(${bg});
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: center;
    height: calc(100vh - 95px);
    width: 100%;
    background-size: cover;

    @media screen and (max-width: 1024px) {
      height: 65vh;
    }

    @media screen and (max-width: 768px) {
      height: 55vh;
    }

    @media screen and (max-width: 480px) {
      background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.25),
          rgba(0, 0, 0, 0.25)
        ),
        url(${bg});
      background-position-x: left;
      height: calc(100lvh - 95px);
    }

    .text-container {
      margin: auto;
      width: 1140px;
      color: #ffffff;
      max-width: 90%;
      padding-top: 1%;

      @media screen and (min-width: 1490px) and (max-width: 1920px) {
        padding-top: 1%;
      }

      @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
      }
      @media screen and (max-width: 480px) {
        display: flex;
        flex-direction: column;
        padding-top: 1rem;
      }

      .left {
        width: 45%;
        padding-top: 4rem;

        @media screen and (max-width: 1024px) {
          width: 100%;
        }
        @media screen and (max-width: 480px) {
          padding-top: 2rem;
        }

        h1 {
          font-size: var(--hero-font-size);
          font-family: var(--hero-font-family);
          margin-bottom: 3rem;
          animation: herotitle 1.2s ease;
          margin-right: 4rem;

          @media screen and (min-width: 1360px) and (max-width: 1600px) {
            margin-right: 8rem;
          }

          @media screen and (min-width: 1025px) and (max-width: 1359px) {
            margin-right: 7rem;
          }

          @media screen and (max-width: 480px) {
            animation: none;
          }
        }

        p {
          line-height: 1.6;
          animation: heroparagraph 1.2s ease;
          @media screen and (max-width: 480px) {
            animation: none;
          }
        }

        button {
          margin-top: 4rem;
          animation: buttonAnim 1.2s ease;

          @media (max-width: 480px) {
            margin-top: 2.5rem;
          }
        }

        @keyframes herotitle {
          from {
            opacity: 0;
            transform: translateX(400px);
          }
          to {
            opacity: 1;
            transform: translateX(0);
          }
        }

        @keyframes heroparagraph {
          from {
            opacity: 0;
            transform: translateY(400px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes buttonAnim {
          from {
            opacity: 0;
            transform: translateX(-400px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      .right {
        width: 55%;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 4rem auto 3rem;
    gap: 2rem;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .left-block {
      width: 50%;
      display: flex;
      flex-direction: column;

      p:first-child {
        margin-block: 1.5rem;
      }

      p {
        line-height: 1.8;
      }
      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      h2 {
        position: relative;
        width: 100%;
        font-size: clamp(1.5rem, 1.26rem + 0.8vw, 2.25rem);
        margin-left: 1rem;
        margin-bottom: 1rem;

        @media screen and (max-width: 480px) {
          width: 100%;
          margin-left: 0;
          ::before {
            display: none;
          }
          ::after {
            position: absolute;
            content: '';
            bottom: -15px;
            left: 0;
            width: 20%;
            height: 5px;
            background-color: var(--secondary-red);
          }
        }

        ::before {
          content: '';
          position: absolute;
          top: 0;
          left: -2rem;
          width: 5px;
          height: 100%;
          background-color: var(--secondary-red);
        }
      }
      .services-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;

        @media screen and (max-width: 480px) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 0;
        }
        .icon-box {
          display: flex;
          align-items: center;

          /* :nth-child(even) {
            justify-content: flex-end;
          }
          :nth-child(odd) {
            justify-content: flex-start;
          } */

          @media screen and (max-width: 1024px) {
            justify-content: center;
            align-items: flex-start;
          }
          a {
            width: 100%;
            max-width: 180px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: var(--text-color);
            cursor: pointer;

            :hover img {
              transform: scale(1.1);
            }

            img {
              max-width: 80px;
              transition: all 0.3s ease;
            }
          }
        }
      }
    }

    .right-block {
      width: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }
`
