import React from 'react'
import close from '../assets/icons/close.svg'
import styled from 'styled-components'

const Gallery = ({
  clickedImg,
  setClickedImg,
  handelRotationRight,
  handelRotationLeft,
}) => {
  const handleClick = () => {
    setClickedImg(undefined)
  }

  return (
    <Wrapper>
      <div className='overlay'>
        <img src={clickedImg} alt='bigger pic' />
        <span className='close-icon'>
          <img src={close} alt='close icon' onClick={handleClick} />
        </span>
        <div onClick={handelRotationLeft} className='overlay-arrows_left'>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              height='48'
              width='48'
              fill='#e23037'>
              <path d='M20 44 0 24 20 4 22.8 6.85 5.65 24 22.8 41.15Z' />
            </svg>
          </div>
        </div>
        <div onClick={handelRotationRight} className='overlay-arrows_right'>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              height='48'
              width='48'
              fill='#e23037'>
              <path d='M15.2 43.9 12.4 41.05 29.55 23.9 12.4 6.75 15.2 3.9 35.2 23.9Z' />
            </svg>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Gallery

const Wrapper = styled.main`
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(18, 18, 18, 0.9);
    display: flex;
    align-items: center;
    z-index: 99999;
  }

  .overlay img {
    display: block;
    max-width: 85%;
    max-height: 85%;
    margin: 60px auto;

    @media screen and (max-width: 769px) {
      width: 100%;
      height: auto;
      margin: auto;
    }
  }
  .overlay > span {
    position: absolute;
    top: 20px;
    right: 35px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
  }
  .overlay-arrows_left {
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    width: 70px;
    height: 70px;
    left: 5%;
    z-index: 999;

    @media screen and (max-width: 769px) {
      top: 75%;
      left: 10%;
    }
  }
  .overlay-arrows_left svg,
  .overlay-arrows_right svg {
    width: 50px;
    height: 50px;
    transition: all 0.3s ease;
  }

  .overlay-arrows_left:hover,
  .overlay-arrows_right:hover {
    cursor: pointer;
  }

  .overlay-arrows_left:hover svg,
  .overlay-arrows_right:hover svg {
    fill: #ffffff;
  }

  .overlay-arrows_right {
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 5%;
    width: 70px;
    height: 70px;
    top: 50%;
    z-index: 999;

    @media screen and (max-width: 769px) {
      top: 75%;
      right: 10%;
    }
  }

  .close-icon {
    transition: all 0.3s ease-in;

    :hover {
      transform: rotate(180deg);
    }
  }
`
