import React, { useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'
import Gallery from '../components/Gallery'
import { Slider } from '../components'

// import logos, images, videos
import marker from '../assets/icons/mini-marker.png'
import right from '../assets/icons/right.png'
import computer from '../assets/images/design.webp'
import { logos } from '../utils/logoContainer'
import { mockups } from '../utils/MockupContainer'

const GraphicsServices = () => {
  const [clickedImg, setClickedImg] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(null)

  const handelRotationRight = () => {
    const totalLength = mockups.length
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0)
      const newImage = mockups[0].img
      setClickedImg(newImage)
      return newImage
    }
    const newIndex = currentIndex + 1
    const img = mockups
    const newImage = img.filter((item) => {
      return img.indexOf(item) === newIndex
    })
    const newItem = newImage[0].img
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
    return newItem
  }

  const handelRotationLeft = () => {
    const totalLength = mockups.length
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1)
      const newImage = mockups[totalLength - 1].img
      setClickedImg(newImage)
      return newImage
    }
    const newIndex = currentIndex - 1
    const newImage = mockups.filter((item) => {
      return mockups.indexOf(item) === newIndex
    })
    const newItem = newImage[0].img
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
    return newItem
  }

  return (
    <MainWrapper>
      <Helmet>
        <meta name='description' content='Digital Creative - Grafikai munkák' />
        <title>Digital Creative - Grafikai munkák</title>
      </Helmet>
      <section className=''>
        <div className='header-block'>
          <div className='img-block'>
            <img
              loading='lazy'
              src={computer}
              alt='Creative Design desktop design'
            />
          </div>
          <div className='text-wrapper'>
            <h2>Grafikai munkák</h2>
            <div className='textblock'>
              <p className='darkgray'>
                Ha megszületik egy új vállalkozás, vagy egy brand, első lépés
                minden esetben egy jól megtervezett logó, egyedi arculat kell
                legyen. Ez azonban nem egyszerű feladat. A logónak teljes
                mértékben tükröznie kell a cég koncepcióját, tevékenységi körét.
              </p>
              <p>
                Fontos a színek, formák, betűtípusok harmóniája. A logónak jól
                kell mutatnia kicsiben, névjegykártyán, nagyban, akár óriás
                plakáton is. Mi tervezünk Önnek egy olyan arculatot, vagy
                weboldalt amely egységet képez tevékenységi köre minden elemével
                és jól használható minden hagyományos és digitális platformon.
              </p>
            </div>
            <ul className='listBlock'>
              <li>Logó tervezés</li>
              <li>Komplett arculat tervezés</li>
              <li>Facebook és Instagram megjelenés</li>
              <li>Hirdetések, szórólapok, prospektusok</li>
            </ul>
            <div className='more'>
              <NavLink to='/kapcsolat'>
                <div className='more-wrapper'>
                  <img src={right} alt='right arrow' />
                  <p className='bold'>Személyre szabott ajánlatot szeretnék!</p>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <Slider datas={logos} />
      <section>
        <div className='bottom-block'>
          <div className='header'>
            <h2>
              Kreatív csapatunk mindig kész az{' '}
              <span className='red'>új ötletek </span>
              megvalósítására!
            </h2>
          </div>
          <div className='quote'>
            <figure className='darkgray'>
              <q>A jó design mindig őszinte.</q>
              <p>- Dieter Rams-</p>
            </figure>
          </div>
          <hr />
          <div className='mockup-container'>
            {mockups.map((mockup) => (
              <div
                key={mockup.id}
                className='img-container'
                onClick={() => setClickedImg(mockup.img)}>
                <img loading='lazy' src={mockup.img} alt={mockup.altTag} />
              </div>
            ))}
          </div>
          {clickedImg && (
            <Gallery
              clickedImg={clickedImg}
              handelRotationRight={handelRotationRight}
              setClickedImg={setClickedImg}
              handelRotationLeft={handelRotationLeft}
            />
          )}
        </div>
        <div className='more'>
          <NavLink to='/kapcsolat'>
            <div className='more-wrapper'>
              <img src={right} alt='right arrow' />
              <p className='bold'>Személyre szabott ajánlatot szeretnék!</p>
            </div>
          </NavLink>
        </div>
        <div className='btn-container'>
          <NavLink to='/szolgaltatasok'>
            <button className='btn'>Vissza a szolgáltatásokhoz</button>
          </NavLink>
        </div>
      </section>
    </MainWrapper>
  )
}

export default GraphicsServices

const MainWrapper = styled.main`
  .header-block {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 4rem;
    padding-top: 100px;
    gap: 2.5rem;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .img-block {
      width: 55%;
      height: 100%;
      background-color: #e5e5e5;
      border-radius: 100%;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        animation: slide 1.5s ease;
      }

      @keyframes slide {
        from {
          opacity: 0;
          transform: translateX(-400px);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    .text-wrapper {
      width: 45%;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      h2 {
        position: relative;

        ::after {
          position: absolute;
          content: '';
          bottom: -3px;
          left: 0;
          width: 20%;
          height: 5px;
          background-color: var(--secondary-red);
        }
      }

      .textblock {
        p {
          margin-bottom: 2rem;
        }
      }

      .listBlock {
        margin-bottom: 2rem;
        li {
          position: relative;
          list-style: none;
          line-height: 2;
          margin-left: 20%;

          @media screen and (max-width: 1024px) {
            margin-left: 30px;
          }

          ::before {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            content: url(${marker});
            width: 16px;
            height: 16px;
            top: 10px;
            left: -30px;
          }
        }
      }
    }
  }

  .bottom-block {
    margin-block: 4rem;
    width: 100%;

    .header {
      width: 80%;

      h2 {
        position: relative;
        margin-left: 30px;

        ::after {
          position: absolute;
          content: '';
          top: -8px;
          left: -30px;
          width: 5px;
          height: 120%;
          background-color: var(--secondary-red);
        }
      }
    }

    .quote {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      figure {
        text-align: center;
      }

      q,
      p {
        font-size: clamp(1.125rem, 1.005rem + 0.4vw, 1.5rem);
      }
    }

    hr {
      border: none;
      width: 90%;
      height: 1px;
      background-color: var(--text-color);
      margin: auto;
      margin-block: 2rem;
    }

    .mockup-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;

      @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
      }

      .img-container {
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.3s ease;

          :hover {
            filter: brightness(0.8);
            transform: scale(1.1);
            cursor: zoom-in;
          }
        }
      }
    }
  }
  .more {
    padding-bottom: 4rem;
    display: flex;

    a {
      text-decoration: none;
      color: var(--text-color);
    }

    .more-wrapper {
      display: flex;
      align-items: center;
      transition: all 0.3s ease;

      img {
        width: 20px;
      }

      p {
        padding-left: 0.5rem;
      }

      :hover {
        transform: scale(1.1);
      }
    }
  }
`
