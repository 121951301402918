import React from 'react'
import styled from 'styled-components'

const References = ({
  clickedImg,
  setClickedImg,
  clickedVideo,
  setClickedVideo,
  handelRotationRight,
  handelRotationLeft,
}) => {
  const desktop = window.innerWidth > 1024
  const handleClick = () => {
    if (clickedImg) {
      setClickedImg(undefined)
    } else {
      setClickedVideo(undefined)
    }
  }

  return (
    <Wrapper>
      <div className='overlay'>
        {clickedImg ? (
          <div className='bigger-pic'>
            <div className='title-block'>
              <h2>{clickedImg.altTag}</h2>
            </div>

            <div className='img-block'>
              <>
                {desktop ? (
                  <img src={clickedImg.img} alt={clickedImg.altTag} />
                ) : (
                  <img src={clickedImg.thumb} alt={clickedImg.altTag} />
                )}
              </>
            </div>
            <div className='text-block'>
              <p>{clickedImg.desc}</p>
            </div>
            <div className='btn-container'>
              {clickedImg.link ? (
                <a
                  href={clickedImg.link}
                  rel='noreferrer noopener'
                  target='_blank'
                  className='btn'>
                  Megnézem
                </a>
              ) : null}
            </div>
          </div>
        ) : (
          <div className='bigger-video'>
            <div className='title-block'>
              <h2>{clickedVideo.altTag}</h2>
            </div>
            <div className='img-block'>
              <iframe
                src={clickedVideo.video}
                title={clickedVideo.altTag}
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;'
                allowfullscreen></iframe>
            </div>
            <div className='text-block'>
              <p>{clickedVideo.desc}</p>
            </div>
            {/* <div className='btn-container'>
              <a
                href='https://www.youtube.com/channel/UC9IL4Nf7q8ldw41lymZr30g'
                rel='noreferrer noopener'
                target='_blank'
                className='btn'>
                Kövessen Youtube csatornánkon is!
              </a>
            </div> */}
          </div>
        )}

        <span className='close-icon'>
          <svg
            className='close-icon'
            onClick={handleClick}
            xmlns='http://www.w3.org/2000/svg'
            height='48'
            width='48'
            fill='#e23037'>
            <path d='M16.5 33.6 24 26.1 31.5 33.6 33.6 31.5 26.1 24 33.6 16.5 31.5 14.4 24 21.9 16.5 14.4 14.4 16.5 21.9 24 14.4 31.5ZM24 44Q19.75 44 16.1 42.475Q12.45 40.95 9.75 38.25Q7.05 35.55 5.525 31.9Q4 28.25 4 24Q4 19.8 5.525 16.15Q7.05 12.5 9.75 9.8Q12.45 7.1 16.1 5.55Q19.75 4 24 4Q28.2 4 31.85 5.55Q35.5 7.1 38.2 9.8Q40.9 12.5 42.45 16.15Q44 19.8 44 24Q44 28.25 42.45 31.9Q40.9 35.55 38.2 38.25Q35.5 40.95 31.85 42.475Q28.2 44 24 44ZM24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24ZM24 41Q31 41 36 36Q41 31 41 24Q41 17 36 12Q31 7 24 7Q17 7 12 12Q7 17 7 24Q7 31 12 36Q17 41 24 41Z' />
          </svg>
        </span>

        <div onClick={handelRotationLeft} className='overlay-arrows_left'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            height='48'
            width='48'
            fill='#e23037'>
            <path d='M20 44 0 24 20 4 22.8 6.85 5.65 24 22.8 41.15Z' />
          </svg>
        </div>

        <div onClick={handelRotationRight} className='overlay-arrows_right'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            height='48'
            width='48'
            fill='#e23037'>
            <path d='M15.2 43.9 12.4 41.05 29.55 23.9 12.4 6.75 15.2 3.9 35.2 23.9Z' />
          </svg>
        </div>
      </div>
    </Wrapper>
  )
}

export default References

const Wrapper = styled.main`
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    background: rgba(18, 18, 18, 0.9);
    z-index: 999;

    .bigger-pic {
      display: block;
      display: flex;
      flex-direction: column;
      width: 60%;
      height: 80%;
      background-color: #ffffff;
      margin: 10% auto;

      @media screen and (max-width: 1024px) {
        width: 100%;
        height: 100vh;
        background-color: #ffffff;
        padding: 5%;
        margin: 0% auto;
      }

      .title-block {
        width: 100%;
        padding: 2% 5.6%;
        height: 10%;
      }

      .img-block {
        width: 89%;
        height: 70%;
        margin: 0 auto;

        iframe {
          width: 100%;
          height: 95%;
          margin-block: 1rem;
        }

        img {
          padding-block: 1rem;
          width: 100%;
          height: 100%;
          object-fit: contain;

          @media (max-width: 1024px) {
            object-fit: contain;
            height: auto;
          }
        }
        @media (max-width: 1024px) {
          width: 90%;
          height: auto;
        }
      }

      .text-block {
        padding: 1% 5.5%;
        height: 20%;
        overflow-y: auto;

        @media (max-width: 1024px) {
          height: auto;
        }
      }
    }

    /* --- Video player block css --- */

    .bigger-video {
      display: block;
      display: flex;
      flex-direction: column;
      width: 60%;
      height: 80%;
      background-color: #ffffff;
      margin: 10% auto;

      @media screen and (max-width: 1024px) {
        width: 100%;
        height: 100vh;
        background-color: #ffffff;
        padding: 5%;
        margin: 0% auto;
      }

      .title-block {
        width: 100%;
        padding: 2% 5.6%;
        height: 10%;

        @media (max-width: 480px) {
          width: 90%;
        }
      }

      .img-block {
        width: 89%;
        height: 83%;
        margin: 0 auto;

        iframe {
          width: 100%;
          height: 98%;
          margin-block: 1rem;
        }

        @media (max-width: 1024px) {
          width: 90%;
          height: 38%;
        }
        @media (max-width: 480px) {
          width: 100%;
          height: 30%;
        }
      }

      .text-block {
        padding: 1% 5.5%;
        height: 17%;
        overflow-y: auto;

        @media (max-width: 1024px) {
          height: auto;
        }
        @media (max-width: 480px) {
          padding: 5% 0;
          min-height: 40%;
        }
      }

      @media screen and (orientation: landscape) and (max-width: 1024px) {
        width: 100%;
        height: 100%;
        padding: 0;

        .title-block,
        .text-block {
          display: none;
        }

        .img-block {
          width: 100%;
          height: 100%;

          iframe {
            width: 100%;
            height: 100%;
            margin-top: 0;
          }
        }
      }
    }
  }

  .overlay > span {
    position: absolute;
    top: 20px;
    right: 35px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
  }
  .overlay-arrows_left {
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    width: 70px;
    height: 70px;
    left: 5%;
    z-index: 9999;

    @media (max-width: 1024px) {
      left: 4%;
      top: 40%;
    }
    @media (max-width: 480px) {
      top: 85%;
    }
  }
  .overlay-arrows_left svg,
  .overlay-arrows_right svg {
    width: 50px;
    height: 50px;
    transition: all 0.3s ease;
  }

  .overlay-arrows_left:hover,
  .overlay-arrows_right:hover {
    cursor: pointer;
  }

  .overlay-arrows_left:hover svg,
  .overlay-arrows_right:hover svg {
    fill: #ffffff;

    @media (max-width: 1024px) {
      fill: var(--secondary-red);
    }
  }

  .overlay-arrows_right {
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 5%;
    width: 70px;
    height: 70px;
    z-index: 9999;

    @media (max-width: 1024px) {
      right: 2%;
      top: 40%;
    }
    @media (max-width: 480px) {
      top: 85%;
    }
  }

  .close-icon {
    transition: all 0.2s ease-in;

    :hover {
      transform: scale(1.1);
    }

    @media (max-width: 1024px) {
      margin-top: 1rem;
    }

    @media (max-width: 480px) {
      margin-top: 0;
    }
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 1rem;

    @media (max-width: 480px) {
      position: fixed;
      width: 100%;
      top: 86.5%;
      left: 0;
    }

    .btn {
      padding: 10px 30px;
    }
  }
`
