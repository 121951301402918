import React, { useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { NavLink, useNavigate } from 'react-router-dom'

import bg from '../assets/images/services_cover_3.webp'
import customDev from '../assets/icons/custom-development.svg'
import websiteDev from '../assets/icons/website-development.svg'
import seo from '../assets/icons/seo.svg'
import video from '../assets/icons/video.svg'
import solutions from '../assets/icons/solutions.png'
import team from '../assets/icons/team.png'
import bulb from '../assets/icons/bulb.png'
import speed from '../assets/icons/speed.png'

const Services = () => {
  const navigate = useNavigate()
  const [isShown, setIsShown] = useState(false)
  const [isStartShown, setIsStartShown] = useState(true)
  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content='Digital Creative - Szolgáltatások' />
        <title>Digital Creative - Szolgáltatások</title>
      </Helmet>
      <div className='bg-container'>
        <section className='mesh-container'>
          <img
            src={customDev}
            alt='custom-development'
            className='icon custom fade'
            onMouseEnter={() => {
              setIsShown('custom')
              setIsStartShown(false)
            }}
            onMouseLeave={() => setIsShown(false)}
            onClick={() => navigate('/szolgaltatasok/egyedi-fejlesztes')}
          />
          <img
            src={websiteDev}
            alt='website-development'
            className='icon website fade'
            onMouseEnter={() => {
              setIsShown('website')
              setIsStartShown(false)
            }}
            onMouseLeave={() => setIsShown(false)}
            onClick={() => navigate('/szolgaltatasok/weboldal-keszites')}
          />
          <img
            src={video}
            alt='video'
            className='icon video fade'
            onMouseEnter={() => {
              setIsShown('video')
              setIsStartShown(false)
            }}
            onMouseLeave={() => setIsShown(false)}
            onClick={() => navigate('/szolgaltatasok/video-spotok')}
          />
          <img
            src={seo}
            alt='seo'
            className='icon seo fade'
            onMouseEnter={() => {
              setIsShown('seo')
              setIsStartShown(false)
            }}
            onMouseLeave={() => setIsShown(false)}
            onClick={() => navigate('/szolgaltatasok/technikai-seo')}
          />
          <div className='services'>
            <h2 className='logo'>SZOLGÁLTATÁSOK</h2>
            <NavLink
              onMouseLeave={() => setIsStartShown(false)}
              to='/szolgaltatasok/egyedi-fejlesztes'
              className={`services-link ${
                isShown === 'custom' ? 'mouseHover' : ''
              } ${isStartShown ? 'mouseHover' : ''}`}>
              <img
                src={customDev}
                alt='custom-development'
                className='icon-mobile mobile-custom'
              />
              Egyedi fejlesztésű weboldalak
            </NavLink>
            <NavLink
              onMouseEnter={() => setIsStartShown(false)}
              to='/szolgaltatasok/weboldal-keszites'
              className={`services-link ${
                isShown === 'website' ? 'mouseHover' : ''
              }`}>
              <img
                src={websiteDev}
                alt='website-development'
                className='icon-mobile'
              />
              <span>Worpress oldalak készítése</span>
            </NavLink>

            <NavLink
              onMouseEnter={() => setIsStartShown(false)}
              to='/szolgaltatasok/video-spotok'
              className={`services-link ${
                isShown === 'video' ? 'mouseHover' : ''
              }`}>
              <img src={video} alt='video' className='icon-mobile fade' />
              Video spotok
            </NavLink>
            <NavLink
              onMouseEnter={() => setIsStartShown(false)}
              to='/szolgaltatasok/technikai-seo'
              className={`services-link ${
                isShown === 'seo' ? 'mouseHover' : ''
              }`}>
              <img src={seo} alt='seo' className='icon-mobile fade' />
              Technikai SEO
            </NavLink>
          </div>
        </section>
      </div>
      <div className='services-header'>
        <div className='services-header-content'>
          <div className='title'>
            <h2>Megmutatjuk az utat a digitális siker eléréséhez.</h2>
            <p>
              Egy korszerű, stabilan működő weboldal segít vállalkozása online
              népszerűsítésében. Mi elkészítjük Önnek!
            </p>
          </div>
        </div>
      </div>
      <div className='why'>
        <section>
          <h2>Miért válasszon minket?</h2>
        </section>
        <section className='why-container'>
          <div className='why-row'>
            <div className='why-item'>
              <div className='why-item-header'>
                <img src={bulb} alt='great-ideas' className='icon' />
                <h3>Nagyszerű ötletek</h3>
              </div>
              <p>
                Önnek csak vázolnia kell elképzeléseit és mi kreatív
                gondolkodásmódunkkal megalkotjuk professzionális weboldalát.
                Csapatunk egyedi igényeket kielégítő, biztonságos, gyors
                webhelyet biztosít, amely segít Önnek vállalkozása
                népszerűsítésében és sikeres online ügyfélszerzési stratégia
                kialakításában.
              </p>
            </div>
            <div className='why-item'>
              <div className='why-item-header'>
                <img src={solutions} alt='solutions' className='icon' />
                <h3>Teljeskörű megoldások</h3>
              </div>
              <p>
                A DIGITAL CREATIVE egy teljes körű szolgáltatást nyújtó kreatív
                fejlesztőcég, amely webfejlesztésre, technikai SEO-ra és video
                spotok készítésére szakosodott. Partenerink segítségével akár
                egy újonnan induló vállalkozás teljes arculatát, online
                megjelenését is kivitelezzük, így nyújtva komplex megoldást
                ügyfeleinknek.
              </p>
            </div>
          </div>
          <div className='why-row'>
            <div className='why-item'>
              <div className='why-item-header'>
                <img src={team} alt='team' className='icon' />
                <h3>Profi csapat</h3>
              </div>
              <p>
                Professzionális webfejlesztő cég vagyunk. Egy csapat, akik
                folyamatosan a legújabb trendeknek megfelelő technológiákat
                használják a webfejlesztés, web design, és a technikai SEO
                területén.
              </p>
            </div>
            <div className='why-item'>
              <div className='why-item-header'>
                <img src={speed} alt='speed' className='icon' />
                <h3>Gyors és megbízható munkavégzés</h3>
              </div>
              <p>
                Dinamikus munkavégzésünk eredményeként az Ön weboldala a lehető
                legrövidebb idő alatt elkezdheti a profit termelését
                vállalkozása számára.
              </p>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  .bg-container {
    background-image: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0.6)
      ),
      url(${bg});
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    height: calc(80vh);
    width: 100%;
    background-size: cover;
    display: flex;

    @media screen and (max-width: 1024px) {
      height: 90vh;
    }
  }

  .mesh-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .mesh {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    .icon {
      height: 80px;
      width: 80px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        transform: translateY(-20px);
      }

      @media screen and (max-width: 1024px) {
        display: none;
      }

      &-mobile {
        height: 50px;
        margin-right: 1rem;

        @media screen and (min-width: 1024px) {
          display: none;
        }
      }
    }

    .custom {
      animation: 3s ease forwards 1 fadeIn;
    }

    .fade {
      opacity: 0;
      animation: fadeIn 3s;
      animation-fill-mode: forwards;
    }

    .fade:nth-child(2) {
      animation-delay: 0.1s;
    }

    .fade:nth-child(3) {
      animation-delay: 0.2s;
    }

    .fade:nth-child(4) {
      animation-delay: 0.3s;
    }

    .fade:nth-child(5) {
      animation-delay: 0.4s;
    }

    .fade:nth-child(6) {
      animation-delay: 0.5s;
    }

    .fade:nth-child(7) {
      animation-delay: 0.6s;
    }

    .services {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1024px) {
        margin-left: 0;
        margin: auto;

        a {
          @media screen and (max-width: 480px) {
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
          }
        }
      }

      .logo {
        display: flex;
        margin-bottom: 3rem;
        font-weight: 600;
        line-height: 0.9;
        text-align: center;
        font-size: clamp(1.875rem, 1.275rem + 1.6vw, 3.75rem);
        color: hsl(0, 0%, 100%);

        @media screen and (max-width: 1024px) {
          margin-top: 0;
          justify-content: center;
        }
      }

      &-link {
        display: flex;
        align-items: flex-start;
        text-decoration: none;
        color: var(--secondary-bg);
        font-size: 1.5rem;
        line-height: 3.5rem;
        animation: 2s ease 0s normal forwards 1 fadeIn;
        transition: all 0.2s ease-in-out;
        width: fit-content;

        @media screen and (min-width: 767px) {
          &:hover {
            transform: translateX(50px) scale(1.1);

            ::before {
              content: '●';
              position: absolute;
              top: 0;
              left: -20px;
            }
          }
        }

        @media screen and (max-width: 1024px) {
          display: flex;
          line-height: 4rem;
          align-items: center;
        }
      }
    }
  }

  .services-header {
    width: 100%;
    background-color: var(--main-gray);

    .services-header-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin: auto;
      width: 1140px;
      max-width: 90%;

      @media screen and (max-width: 480px) {
        flex-direction: column;
      }

      .title {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 53%;
        justify-content: flex-start;
        margin-block: 3.5rem;

        p {
          margin-top: 1rem;
        }

        ::before {
          position: absolute;
          content: '';
          top: -5%;
          left: -50px;
          width: 7px;
          height: 110%;
          background-color: var(--secondary-red);
        }

        @media screen and (max-width: 480px) {
          width: 100%;
          ::before {
            display: none;
          }
          ::after {
            position: absolute;
            content: '';
            bottom: -15px;
            left: 0;
            width: 100%;
            height: 7px;
            background-color: var(--secondary-red);
          }
        }
      }
    }
  }
  .why {
    background: var(--main-bg);
    color: var(--secondary-bg);
    padding: 3rem 0;

    h2 {
      font-weight: 500;
      text-align: center;
      margin-bottom: 3rem;
      animation: title 2s;
    }

    @keyframes title {
      from {
        transform: translate(100%, 0);
      }
      to {
        transform: translate(0%, 0%);
      }
    }

    &-row {
      display: flex;
      margin-bottom: 2rem;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
        margin-bottom: 0;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      flex: 0 0 50%;

      &-header {
        display: flex;
        align-items: center;
        flex-direction: row;
        .icon {
          height: 64px;
          margin-right: 2rem;
        }

        h3 {
          font-size: 1.875rem;
          font-weight: 500;

          @media screen and (max-width: 1024px) {
            font-size: 1.5rem;
            margin-top: 1rem;
          }
        }
      }
      p {
        margin: 1rem 2rem 1rem 0;
        padding-left: 6rem;
        word-wrap: break-word;
        line-height: 1.8rem;

        @media screen and (max-width: 1024px) {
          padding-left: 0;
          margin: 1.5rem 0 1rem 0;
        }
      }
    }
  }
  @media screen and (min-width: 767px) {
    .mouseHover {
      transform: translateX(50px) scale(1.1);

      ::before {
        content: '●';
        position: absolute;
        top: 0;
        left: -20px;
      }
    }
  }
  /* Animation */

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export default Services
