import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

// import logos, images, videos
import marker from '../assets/icons/mini-marker.png'
import right from '../assets/icons/right.png'
import bg from '../assets/images/social_cover.webp'
import social from '../assets/images/social-grafiti.webp'
import dc from '../assets/images/dc-grafiti.webp'
import like from '../assets/images/like-grafiti.webp'
import smiling from '../assets/images/smiling-woman.webp'
import facebook from '../assets/icons/face.png'
import insta from '../assets/icons/insta.png'
import twitter from '../assets/icons/twitter.png'
import fb from '../assets/icons/social-fb.png'
import instalogo from '../assets/icons/social-insta.png'
import twitterlogo from '../assets/icons/social-twitter.png'

const SocialServices = () => {
  return (
    <MainWrapper>
      <Helmet>
        <meta
          name='description'
          content='Digital Creative - Social Services / Közösségi média'
        />
        <title>Digital Creative - Közösségi média</title>
      </Helmet>
      <div className='bg-container'>
        <div className='img-wrapper'>
          <div className='dc-img'>
            <img src={dc} alt='digital creative grafiti logo' />
          </div>
          <div className='like-img'>
            <img src={like} alt='digital creative like logo' />
          </div>
          <div className='title-img'>
            <img src={social} alt='social media title logo' />
          </div>
        </div>
      </div>
      <section>
        <div className='content-container'>
          <div className='img-container'>
            <img src={smiling} alt='smiling women' />
          </div>
          <div className='text-container'>
            <h2>
              Fedezze fel a <span className='red'>korlátok nélküli </span>
              világot!
            </h2>
            <div className='textblock'>
              <div className='facebook'>
                <img src={facebook} alt='facebook' />
                <p className='darkgray'>
                  Mindenki használja a facebook-ot. Mi segítünk abban, hogy Ön
                  illetve a vállalkozása jól használja, illetve kihasználja a
                  benne lévő lehetőségeket. Megbeszélés alapján elkészítjük a
                  vállalkozása megfelelő üzleti facebook oldalát, valamint
                  megszervezzük facebook kampányait is, hogy maximálisan
                  kiaknázhassa a lehetőségeket.
                </p>
              </div>
              <div className='insta'>
                <img src={insta} alt='insta' />
                <p className='darkgray'>
                  Hatalmas téveszme az, hogy az Instagram a fiatalok,
                  tinédzserek játszótere. Mára az egyik legdinamikusabban
                  fejlődő marketing platform, ami ügyfelekhez és plusz
                  bevételhez segítheti hozzá.
                </p>
              </div>
              <div className='twitter'>
                <img src={twitter} alt='twitter' />
                <p className='darkgray'>
                  Bár nem a leghatékonyabb marketing platform, megfelelően
                  használva tömegekhez juttathat el vállalkozásával kapcsolatos
                  híreket, információkat. Ha komolyan veszi vállalkozását és
                  minél több csatornán be szeretne vonzani ügyfeleket, a világ
                  vezető mikroblog szolgáltatását sem hagyhatja figyelmen kívül.
                </p>
              </div>
            </div>
            <div className='social-icons'>
              <img src={fb} alt='facebook' />
              <img src={instalogo} alt='instagram' />
              <img src={twitterlogo} alt='twitter' />
            </div>
            <div className='more'>
              <NavLink to='/kapcsolat'>
                <div className='more-wrapper'>
                  <img src={right} alt='right arrow' />
                  <p className='bold'>Érdekel!</p>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        <div className='btn-container'>
          <NavLink to='/szolgaltatasok'>
            <button className='btn'>Vissza a szolgáltatásokhoz</button>
          </NavLink>
        </div>
      </section>
    </MainWrapper>
  )
}

export default SocialServices
const MainWrapper = styled.main`
  .bg-container {
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: top;
    height: calc(100vh - 95px);
    width: 100%;
    background-size: cover;

    @media screen and (max-width: 1024px) {
      height: 50vh;
    }

    .img-wrapper {
      position: relative;
      margin: auto;
      width: 1140px;
      max-width: 90%;

      .dc-img {
        position: absolute;
        width: 35%;
        right: 0;
        top: 50px;

        @media screen and (max-width: 480px) {
          width: 40%;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .like-img {
        position: absolute;
        width: 8%;
        right: 0;
        top: 45px;
        animation: herolike 2s ease;

        @media screen and (max-width: 480px) {
          width: 10%;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .title-img {
        position: absolute;
        width: 35%;
        left: 0;
        top: 75px;
        animation: herotitle 1.5s ease;

        @media screen and (max-width: 480px) {
          width: 55%;
          top: 45px;
          animation: herotitle-mobil 1.5s ease;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .content-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 3rem;
    margin-block: 4rem;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    @media screen and (max-width: 768px) {
      margin-block: 3rem;
    }

    .social-icons img {
      width: 60px;
      margin: 0 1rem 1.5rem 0;
    }

    .img-container {
      width: 60%;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        min-height: 800px;
        object-fit: cover;
      }
    }

    .text-container {
      width: 40%;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      .facebook img,
      .twitter img {
        height: 30px;
        margin: 1rem 0;
      }

      .insta img {
        height: 41px;
        margin: 1rem 0;
      }

      h2 {
        position: relative;
        margin-left: 20px;
        font-size: clamp(1.5rem, 1.26rem + 0.8vw, 2.25rem);

        ::after {
          position: absolute;
          content: '';
          top: 10%;
          left: -25px;
          width: 5px;
          height: 90%;
          background-color: var(--secondary-red);
        }
      }
      .textblock {
        p {
          margin-bottom: 2rem;
        }
      }

      .listBlock {
        margin-bottom: 2rem;
        li {
          position: relative;
          list-style: none;
          line-height: 2;
          margin-left: 20%;

          @media screen and (max-width: 1024px) {
            margin-left: 30px;
          }

          ::before {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            content: url(${marker});
            width: 16px;
            height: 16px;
            top: 10px;
            left: -30px;
          }
        }
      }
    }
  }

  @keyframes herolike {
    from {
      top: -1500px;
    }
    to {
      top: 45px;
    }
  }
  @keyframes herotitle {
    from {
      top: -500px;
    }
    to {
      top: 75px;
    }
  }

  @keyframes herotitle-mobil {
    from {
      top: -500px;
    }
    to {
      top: 45px;
    }
  }

  .more {
    display: flex;

    a {
      text-decoration: none;
      color: var(--text-color);
    }
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }

  .more-wrapper {
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    img {
      width: 20px;
    }

    p {
      padding-left: 0.5rem;
    }

    :hover {
      transform: scale(1.1);
    }
  }
`
