import React, { useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'

import References from '../components/References'
import { mockups } from '../utils/CustomRefData'

import bg from '../assets/images/custom_develop_cover.webp'
import speedbg from '../assets/images/speed_bg.webp'
import html from '../assets/icons/html.svg'
import css from '../assets/icons/css.svg'
import js from '../assets/icons/js.svg'
import react from '../assets/icons/react.svg'
import astro from '../assets/icons/astro.svg'
import git from '../assets/icons/git.svg'
import github from '../assets/icons/github.svg'
// import node from '../assets/images/node.png'
import right from '../assets/icons/right.png'
import bulbflower from '../assets/icons/lightbulb.svg'
import plus from '../assets/icons/plus.svg'

function CustomServices() {
  const [clickedImg, setClickedImg] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(null)

  const handelRotationRight = () => {
    const totalLength = mockups.length
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0)
      const newImage = mockups[0]
      setClickedImg(newImage)
      return newImage
    }
    const newIndex = currentIndex + 1
    const newImage = mockups.filter((item) => {
      return mockups.indexOf(item) === newIndex
    })
    const newItem = newImage[0]
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
    return newItem
  }

  const handelRotationLeft = () => {
    const totalLength = mockups.length
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1)
      const newImage = mockups[totalLength - 1]
      setClickedImg(newImage)
      return newImage
    }
    const newIndex = currentIndex - 1
    const newImage = mockups.filter((item) => {
      return mockups.indexOf(item) === newIndex
    })
    const newItem = newImage[0]
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
    return newItem
  }

  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content='Digital Creative - Video Spotok' />
        <title>Digital Creative - Egyedi weboldal fejlesztés</title>
      </Helmet>
      <div className='page-hero'>
        <section className='hero-container'>
          <div className='hero-title-custom'>
            <h1>Egyedi weboldalak fejlesztése</h1>
          </div>
          <div className='hero-text'>
            <p>
              A mai webfejlesztési problémák összetettek, speciálisak és
              folyamatosan változnak. Ki tud lépést tartani a legújabb
              trendekkel és technológiákkal? Mi tudunk!
            </p>
            <p>
              A legújabb technológiákat alkalmazzuk, melyek lehetővé teszik az
              általunk készített weboldalak hatékony műkődését, bővítési
              lehetőségét és fenntarthatóságát.
            </p>
            <div className='icons'>
              <img src={html} alt='html5' />
              <img src={css} alt='css3' />
              <img src={js} alt='javascript' />
              <img src={react} alt='react.js' />
              <img src={astro} alt='astro' />
              <img src={git} alt='git' />
              <img src={github} alt='github' />
              {/* <img src={node} alt='node' /> */}
            </div>
          </div>
        </section>
      </div>
      <section>
        <div className='content-wrapper'>
          <div className='first-block'>
            <div className='grey-bg'>
              <img src={bulbflower} alt='idea' />
            </div>
            <div className='top-content'>
              <h2>
                Ötlettől a megvalósításig<span className='red'> szakértő</span>
                csapattal
              </h2>

              <div className='textblock'>
                <p>
                  <span className='bold'>Egyedi fejlesztésűnek</span> nevezzük
                  azokat a weboldalakat, melyeket a megrendelőink igényei
                  alapján programozunk, kódolunk. Ebben az esetben a design is
                  egyedi, melyet designer-grafikus kollégánk az ügyfél személyes
                  elképzelései, kívánságai alapján a weboldal hatékonyságát,
                  használhatóságát szem előtt tartva tervez meg. A piaci
                  igényeknek és az IT szektor elvárásainak legmegfelelőbb modern
                  technológiákat alkalmazzuk, amelyek lehetővé teszik az
                  általunk készített weboldalak hatékony bővítését és
                  skálázhatóságát, hosszú távú fenntarthatóságát, valamint a
                  költségek lehető legrövidebb idő alatti megtérülését.
                </p>
              </div>
              <div className='textblock'>
                <p>
                  <span className='bold'>Stabil, gyors működés.</span> Az egyedi
                  fejlesztésű oldalak sajátossága a “robusztus” felépítés,
                  kisebb kódméret, ennek köszönhetően működése gyorsabb, kisebb
                  erőforrás igényű mint a WordPress alapú oldalaké. Emellett
                  egyáltalán nem vagy csak minimális karbantartást igényelnek.
                </p>
              </div>
              <div className='textblock'>
                <p>
                  <span className='bold'>Egyszerű kezelhetőség.</span> Egyedi
                  fejlesztés esetén a CMS (tartalom menedzselő) rendszer is
                  egyedi, az ügyfél igényeire szabott, így azt laikus,
                  informatikai területen teljesen képzetlen személy is tudja
                  kezelni, tartalommal feltölteni annak veszélye nélkül, hogy
                  magában az oldalban, illetve annak működésében problémát
                  okozna.
                </p>
              </div>
              <div className='textblock'>
                <p>
                  <span className='bold'>Gazdaságosság.</span> Bár az egyedi
                  fejlesztés egyszeri költsége általában (de nem feltétlenül!)
                  magasabb mint a WordPress oldalaké, a robusztussága és a
                  stabilitása miatt nem, vagy csak igen ritkán szükséges
                  karbantartani, ezért nincs további kiadás (kivéve domain
                  hosszabbítás, szerver költség, de ezek WordPress oldalnál is
                  vannak). Ezért az ügyfél ebben az esetben megspórolja a
                  karbantartási szerződés fix havi díját is.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='speed'>
        <section className='speed-container'>
          <p>
            A gyors weboldal megteremtése kulcsfontosságú a látogatók
            megtartásában, amely végül ügyfelekké teszi őket. Többféle technika
            áll rendelkezésre hogy gyors weboldalt készítsünk, amelyeket munkánk
            során alkalmazunk.
          </p>
          {/* <h2>Az oldal sebessége</h2> */}
        </section>
      </div>
      <section className='mockup-wrapper'>
        <h2>Egyedi weboldal (React.js, Astro) referenciák</h2>
        <div className='mockup-container'>
          {mockups.map((mockup) => (
            <div
              key={mockup.id}
              className='img-container'
              onClick={() => {
                setClickedImg(mockup)
              }}>
              <div className='thumbnail-container'>
                <div className='thumbnail'>
                  <img src={mockup.thumb} alt={mockup.altTag} />
                </div>
                <div className='plus'>
                  <img src={plus} alt={mockup.altTag} />
                </div>
              </div>
            </div>
          ))}
        </div>

        {clickedImg && (
          <References
            clickedImg={clickedImg}
            handelRotationRight={handelRotationRight}
            setClickedImg={setClickedImg}
            handelRotationLeft={handelRotationLeft}
          />
        )}
      </section>
      <div className='wantmore'>
        <section className='wantmore-container'>
          <div className='btn-container'>
            <NavLink to='/szolgaltatasok'>
              <button className='btn'>Vissza a szolgáltatásokhoz</button>
            </NavLink>
          </div>
          <NavLink to={`/kapcsolat`}>
            <div className='wantmore-wrapper'>
              <img src={right} alt='right arrow' />
              <p className='bold'>Szeretnék egy profi weboldalt</p>
            </div>
          </NavLink>
        </section>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  .page-hero {
    position: relative;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url(${bg});
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: center;
    height: 650px;
    width: 100%;
    background-size: cover;
    display: flex;

    @media screen and (max-width: 1024px) {
      background-position-y: center;
      background-position-x: 100%;
      height: 100%;
    }
  }

  .hero-container {
    display: flex;
    position: relative;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      margin-top: 2rem;
    }

    .icons {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 1024px) {
        padding-bottom: 2rem;
      }

      img {
        height: 64px;

        @media screen and (max-width: 768px) {
          height: 45px;
        }
      }
    }
  }

  .hero-title-custom h1 {
    color: var(--secondary-bg);
    font-size: 3rem;
    line-height: 1.1;
    font-weight: 400;
    position: relative;

    @media screen and (max-width: 1024px) {
      font-size: 2rem;
    }
  }

  .hero-text {
    color: var(--secondary-bg);
    width: 80%;
    margin-left: 3rem;

    @media screen and (max-width: 1024px) {
      margin-left: 0;
      margin-top: 1.5rem;
      width: 100%;
    }

    p {
      margin-bottom: 2rem;
      line-height: 1.8;
    }
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    margin-block: 3rem;

    .grey-bg {
      position: absolute;
      right: 0;
      top: 0;
      height: 99.5%;
      width: 50%;
      background-color: var(--main-gray);
      z-index: -1;

      img {
        position: absolute;
        width: 84px;
        height: 84px;
        right: 30%;
        top: 4%;
        animation: fade 2s ease, spin 1s ease 2.2s alternate;
      }

      @keyframes fade {
        0% {
          display: none;
          scale: 0;
          opacity: 0;
        }
        100% {
          display: block;
          scale: 1;
          opacity: 1;
        }
      }

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    .first-block {
      position: relative;
      width: 100%;
      height: 100%;
      padding-left: 7%;

      @media screen and (max-width: 768px) {
        padding-left: 0;
      }

      .top-content {
        display: flex;
        flex-direction: column;

        .icon {
          height: 83px;
          width: 83px;
        }

        h2 {
          position: relative;
          width: 50%;
          font-size: clamp(1.5rem, 1.26rem + 0.8vw, 2.25rem);
          margin-left: 1rem;
          margin-bottom: 1rem;

          span {
            margin-right: 0.5rem;
          }

          @media screen and (max-width: 1024px) {
            width: 100%;
          }

          @media screen and (max-width: 768px) {
            width: 100%;
            margin-left: 0;

            ::before {
              display: none;
            }

            ::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: -5px;
              width: 20%;
              height: 5px;
              background-color: var(--secondary-red);
            }
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -2rem;
            width: 5px;
            height: 100%;
            background-color: var(--secondary-red);
          }
        }

        .textblock {
          margin-block: 1rem;
          width: 90%;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }
  .wantmore {
    padding: 0 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem 0 3rem 0;
    width: 100%;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      justify-content: center;
      padding: 0;
      width: 100%;
      justify-content: space-around;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 2rem 0 0 0;
      justify-content: center;
      width: 100%;
    }

    .wantmore-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    .btn-container {
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        order: 2;
      }
    }

    a {
      text-decoration: none;
      color: var(--text-color);
    }
    .wantmore-wrapper {
      display: flex;
      align-items: center;
      transition: all 0.3s ease;

      @media screen and (max-width: 768px) {
        order: 1;
      }
      &:hover {
        transform: scale(1.1);
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
      }

      p {
        font-size: 1.25rem;
        margin: 0;
        color: var(--text-color);

        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }
    }
  }
  .speed {
    position: relative;
    background-image: linear-gradient(
        0deg,
        rgba(26, 26, 26, 0.2),
        rgba(26, 26, 26, 0.2)
      ),
      url(${speedbg});
    /* min-height: 140vh; */
    width: 100%;
    height: 85vh;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    display: flex;

    @media screen and (max-width: 1024px) {
      min-height: 50vh;
      height: 50vh;
      background-image: linear-gradient(
          0deg,
          rgba(26, 26, 26, 0.6),
          rgba(26, 26, 26, 0.6)
        ),
        url(${speedbg});
    }

    &-container {
      display: flex;
      margin: 5rem auto;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
        margin: auto;
      }
    }

    p {
      flex: 3;
      margin-right: 5rem;
      font-size: 1.5rem;

      @media screen and (max-width: 1024px) {
        order: 1;
        flex: 1;
        font-size: 1rem;
        margin-top: 1rem;
        line-height: 1.6;
        margin-right: 0;
      }
    }

    h2 {
      flex: 3;
      text-align: right;
      font-size: clamp(1.5rem, 1.26rem + 0.8vw, 2.25rem);

      @media screen and (max-width: 1024px) {
        flex: 1;
        text-align: left;
      }
    }

    p,
    h2 {
      color: var(--secondary-bg);
    }
  }

  .mockup-wrapper {
    margin-top: 3rem;

    h2 {
      position: relative;
      width: 100%;
      font-size: clamp(1.5rem, 1.26rem + 0.8vw, 2.25rem);
      margin-left: 2rem;
      margin-bottom: 2rem;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-left: 0;

        ::before {
          display: none;
        }

        ::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -5px;
          width: 20%;
          height: 5px;
          background-color: var(--secondary-red);
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -2rem;
        width: 5px;
        height: 100%;
        background-color: var(--secondary-red);
      }
    }

    .mockup-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;

      @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
      }

      .img-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        cursor: pointer;

        .thumbnail-container {
          position: relative;
          width: 100%;
          height: 100%;
          :hover .plus {
            opacity: 1;
          }

          .thumbnail {
            width: 100%;
            height: 100%;
            background-color: #dbdbdb;
            padding: 1.5rem;
            display: grid;
            place-content: center;

            img {
              width: 100%;
              height: auto;
              object-fit: cover;
              transition: all 0.3s ease;
              filter: brightness(0.9);
            }
          }

          .plus {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--secondary-red);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;
            filter: opacity(0.75);
            img {
              width: 20%;
              height: 20%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
`

export default CustomServices
