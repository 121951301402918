import React, { useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'

import References from '../components/References'
import { mockups } from '../utils/WpRefData'

import development from '../assets/images/website-hero.jpg'
import globe from '../assets/icons/globe.png'
import right from '../assets/icons/right.png'
import logo from '../assets/images/dc-grafiti.webp'
import tablet from '../assets/images/tablet.webp'
import desktop from '../assets/icons/desktop.png'
import plus from '../assets/icons/plus.svg'

function WebsiteServices() {
  const [clickedImg, setClickedImg] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(null)

  const handelRotationRight = () => {
    const totalLength = mockups.length
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0)
      const newImage = mockups[0]
      setClickedImg(newImage)
      return newImage
    }
    const newIndex = currentIndex + 1
    const img = mockups
    const newImage = img.filter((item) => {
      return img.indexOf(item) === newIndex
    })
    const newItem = newImage[0]
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
    return newItem
  }

  const handelRotationLeft = () => {
    const totalLength = mockups.length
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1)
      const newImage = mockups[totalLength - 1]
      setClickedImg(newImage)
      return newImage
    }
    const newIndex = currentIndex - 1
    const newImage = mockups.filter((item) => {
      return mockups.indexOf(item) === newIndex
    })
    const newItem = newImage[0]
    setClickedImg(newItem)
    setCurrentIndex(newIndex)
    return newItem
  }
  return (
    <Wrapper>
      <Helmet>
        <meta name='description' content='Digital Creative - Video Spotok' />
        <title>Digital Creative - Wordpress weboldal készítés</title>
      </Helmet>
      <div className='page-hero'>
        <section className='hero-container'>
          <div className='hero-content'>
            <div className='hero-title-website'>
              <h1>Wordpress Weboldalak készítése</h1>
            </div>
            <div className='hero-text'>
              <p>
                A legújabb trendeknek megfelelő weboldalakat készítünk, melyek
                teljes mértékben igazodnak az Ön vállalkozása arculatához, szín-
                és formavilágához. Az általunk alkotott weboldalak megjelenése
                minden esetben letisztult, átlátható, függetlenül attól milyen
                eszközön tekinti meg.
              </p>
              <p>
                Professzionális webfejlesztő cég vagyunk, technikai szakértőkből
                álló csapattal, akik mindig naprakészek a webfejlesztés,
                webdesign, SEO és marketing legújabb trendjeivel.
              </p>
              <p>
                <strong>Statégia, tervezés, fejlesztés, támogatás</strong> - ezt
                nyújta Önnek a DIGITAL CREATIVE csapata
              </p>
            </div>
          </div>
          <div className='hero-image'>
            <img src={development} alt='development' />
            <img src={globe} alt='website' className='hero-icon' />
          </div>
        </section>
      </div>
      <div className='grey-bg'>
        <section className='grey-container'>
          <div className='grey-img'>
            <img src={tablet} alt='tablet' />
          </div>
          <div className='grey-text'>
            <h2>Szakértelmünk az Ön üzleti fejlődéséért</h2>
            <img src={logo} alt='logo' />
            <div className='wantmore'>
              <NavLink to={`/kapcsolat`}>
                <div className='wantmore-wrapper'>
                  <img src={right} alt='right arrow' />
                  <p className='bold'>Árajánlatot kérek!</p>
                </div>
              </NavLink>
            </div>
          </div>
        </section>
      </div>
      <section>
        <div className='content-wrapper'>
          <div className='first-block'>
            <img src={desktop} alt='desktop' />
            <div className='top-content'>
              <h2>
                Reszponzív weboldalak <span className='red'>minden </span>
                eszközre
              </h2>
              <div className='textblock'>
                <p className='first-p'>
                  <span className='bold'>Sok esetben az ügyfél igényeit </span>
                  ár/teljesítmény/megtérülés viszonylatban nem az egyedi
                  fejlesztés szolgálja ki. Ilyenkor javasoljuk a WordPress
                  oldalak elkészítésének lehetőségét.
                </p>
              </div>
              <div className='textblock'>
                <p>
                  A WordPress egy weboldalak, blogok, webshopok készítésére is
                  alkalmas, nagy népszerűségnek örvendő rendszer. Van lehetőség
                  előre elkészített ún. template-ek alapján is oldalak
                  készítésére, azonban természetesen igény esetén a Digital
                  Creative designere által készített egyedi design
                  megvalósítására is van lehetőség.
                </p>
              </div>
              <div className='textblock'>
                <p>
                  <span className='bold'>Könnyű bővíthetőség:</span> Az egyik
                  oka annak, hogy a WordPress olyan népszerű, az az úgynevezett
                  "bővítmények" hatalmas gyűjteménye. Ezek használatával nagyon
                  könnyen lehet extra funkciókat hozzáadni az oldalhoz (pl.
                  foglalási rendszer, képgaléria stb.), azonban ezek jelentik a
                  platform egyik leggyengébb pontját biztonsági szempontból.
                </p>
              </div>
              <div className='textblock'>
                <p>
                  <span className='bold'>Testreszabhatóság:</span> Fejlesztő
                  bevonása nélkül is viszonylag egyszerűen módosítható az oldal,
                  létrehozható blog, cikkek vagy cserélhető kép/szöveg. Azonban
                  amennyiben nem szeretne ezzel foglalkozni, csapatunk vállalja
                  az oldal elkészülése után a módosításokat/karbantartást is. A
                  webdesigner által tervezett WordPress weboldal egy egyedi
                  fejlesztésű honlap funkcióit kínálja, adott esetben rövidebb
                  időn belül, olcsóbban. Tartalomkezelő rendszerként (CMS)
                  emellett gyors és egyszerű frissítéseket, kiegészítéseket és
                  módosításokat tesz lehetővé webhelyén, azonban kedvelt
                  célpontja a támadásoknak, illetve a több, különböző fejlesztő
                  által készített bővítmények miatt rendszeres ellenőrzést,
                  karbantartást igényel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mockup-wrapper'>
        <h2>Wordpress weboldal referenciák</h2>
        <div className='mockup-container'>
          {mockups.map((mockup) => (
            <div
              key={mockup.id}
              className='img-container'
              onClick={() => {
                setClickedImg(mockup)
              }}>
              <div className='thumbnail-container'>
                <div className='thumbnail'>
                  <img src={mockup.thumb} alt={mockup.altTag} />
                </div>
                <div className='plus'>
                  <img src={plus} alt={mockup.altTag} />
                </div>
              </div>
            </div>
          ))}
        </div>

        {clickedImg && (
          <References
            clickedImg={clickedImg}
            handelRotationRight={handelRotationRight}
            setClickedImg={setClickedImg}
            handelRotationLeft={handelRotationLeft}
          />
        )}
      </section>
      <section>
        <div className='wantmore'>
          <div className='btn-container'>
            <NavLink to='/szolgaltatasok'>
              <button className='btn'>Vissza a szolgáltatásokhoz</button>
            </NavLink>
          </div>
          <NavLink to={`/kapcsolat`}>
            <div className='wantmore-wrapper'>
              <img src={right} alt='right arrow' />
              <p className='bold'>Szeretnék egy profi weboldalt</p>
            </div>
          </NavLink>
        </div>
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  .hero-container {
    display: flex;
    padding-top: 3.75rem;
    padding-bottom: 5rem;

    @media screen and (max-width: 1024px) {
      display: block;
      padding-bottom: 3rem;
    }

    .hero-title-website {
      h1 {
        font-size: 3rem;
        line-height: 1.6;
        font-weight: 400;
        position: relative;
        margin-bottom: 3rem;

        @media screen and (max-width: 1024px) {
          margin-bottom: 2rem;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 20%;
          height: 5px;
          background-color: var(--secondary-red);
        }
        @media screen and (max-width: 1024px) {
          font-size: 2rem;
        }
      }
    }

    .hero-text {
      p {
        margin: 2rem 5rem 0 0;
        line-height: 1.6;

        @media screen and (max-width: 1024px) {
          margin: 0 0 2rem 0;
        }
      }
    }

    .hero-image {
      position: relative;
      img {
        height: 550px;
        width: 550px;
        object-fit: cover;

        @media screen and (min-width: 1025px) and (max-width: 1230px) {
          height: 400px;
          width: 400px;
        }

        @media screen and (max-width: 1024px) {
          width: 100%;
          height: 400px;
        }
      }

      .hero-icon {
        position: absolute;
        height: 120px;
        width: 120px;
        right: 20px;
        top: 20px;

        @media screen and (max-width: 768px) {
          height: 60px;
          width: 60px;
        }
      }
    }
  }

  .grey-bg {
    background-color: #fff;

    .grey-container {
      display: flex;

      @media screen and (max-width: 1024px) {
        justify-content: center;
      }

      .grey-img img {
        width: 830px;
        margin: -150px auto 0 -175px;

        @media screen and (min-width: 1025px) and (max-width: 1230px) {
          width: 600px;
          margin: -130px auto 0 -175px;
        }

        @media screen and (max-width: 1024px) {
          display: none;
        }
      }

      .grey-text {
        margin-top: 3rem;

        h2 {
          position: relative;
          line-height: 1.2;
          font-size: 2.25rem;
          margin-right: 2rem;

          @media screen and (max-width: 1024px) {
            font-size: clamp(1.5rem, 1.26rem + 0.8vw, 2.25rem);
            margin-right: 0;
          }

          &::before {
            position: absolute;
            content: '';
            top: -5%;
            left: -50px;
            width: 7px;
            height: 110%;
            background-color: var(--secondary-red);
          }

          @media screen and (max-width: 768px) {
            width: 100%;
            margin-left: 0;

            ::before {
              display: none;
            }

            ::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: -5px;
              width: 20%;
              height: 5px;
              background-color: var(--secondary-red);
            }
          }
        }
        img {
          width: 480px;
          margin: 2rem 0 0 -1.3rem;

          @media screen and (max-width: 1024px) {
            width: 100%;
            margin: 2rem 0 0 -1rem;
          }
        }
      }

      .wantmore {
        padding: 0 15px;
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: 1024px) {
          justify-content: center;
          padding: 0;
          margin: 0 0 3rem 0;
        }

        a {
          text-decoration: none;
          color: var(--text-color);
        }
        .wantmore-wrapper {
          display: flex;
          align-items: center;
          transition: all 0.3s ease;

          img {
            width: 20px;
          }

          p {
            font-size: 1.25rem;
            margin: 1.5rem 0 -0.5rem 1rem;

            @media screen and (max-width: 768px) {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  .content-wrapper {
    width: 100%;
    margin: auto;
    margin-top: 4rem;
    position: relative;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .first-block {
      img {
        position: absolute;
        width: 84px;
        height: 84px;
        right: 0;
        animation: fade 2s ease, spin 1s ease 2.2s alternate;

        @keyframes fade {
          0% {
            display: none;
            scale: 0;
            opacity: 0;
          }
          100% {
            display: block;
            scale: 1;
            opacity: 1;
          }
        }

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        @media screen and (max-width: 1024px) {
          display: none;
        }
      }

      p {
        line-height: 1.6;
      }
    }

    .top-content {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      .first-p {
        margin-top: 2rem;
      }

      h2 {
        position: relative;
        width: 60%;
        font-size: clamp(1.5rem, 1.26rem + 0.8vw, 2.25rem);
        margin-left: 1rem;
        margin-bottom: 1rem;

        @media screen and (max-width: 768px) {
          width: 100%;
          margin-left: 0;

          ::before {
            display: none;
          }

          ::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 20%;
            height: 5px;
            background-color: var(--secondary-red);
          }
        }

        ::before {
          content: '';
          position: absolute;
          top: 0;
          left: -2rem;
          width: 5px;
          height: 100%;
          background-color: var(--secondary-red);
        }
      }
    }
  }
  .wantmore {
    padding: 0 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 2rem 0 3rem 0;
    width: 90%;

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      justify-content: center;
      padding: 0;
      width: 100%;
      justify-content: space-around;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin: 2rem 0 2rem 0;
      justify-content: center;
      padding: 0;
      width: 100%;
    }

    .btn-container {
      margin-bottom: 0;

      @media screen and (max-width: 767px) {
        order: 2;
      }
    }

    a {
      text-decoration: none;
      color: var(--text-color);
    }
    .wantmore-wrapper {
      display: flex;
      align-items: center;
      transition: all 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 0.5rem;
      }

      p {
        font-size: 1.25rem;
        margin: 0;

        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }
    }
  }
  .mockup-wrapper {
    margin-top: 3rem;

    h2 {
      position: relative;
      width: 50%;
      font-size: clamp(1.5rem, 1.26rem + 0.8vw, 2.25rem);
      margin-left: 2rem;
      margin-bottom: 2rem;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-left: 0;

        ::before {
          display: none;
        }

        ::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -5px;
          width: 20%;
          height: 5px;
          background-color: var(--secondary-red);
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -2rem;
        width: 5px;
        height: 100%;
        background-color: var(--secondary-red);
      }
    }

    .mockup-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;

      @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
      }

      .img-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        cursor: pointer;

        .thumbnail-container {
          position: relative;
          width: 100%;
          height: 100%;
          background-color: #dbdbdb;
          padding: 1.5rem;
          display: grid;
          place-content: center;

          :hover .plus {
            opacity: 1;
          }

          .thumbnail {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: auto;
              object-fit: cover;
              transition: all 0.3s ease;
              filter: brightness(0.9);
            }
          }

          .plus {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--secondary-red);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;
            filter: opacity(0.75);
            img {
              width: 20%;
              height: 20%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
`

export default WebsiteServices
