import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'

import bg from '../assets/images/about.webp'

const AboutUs = () => {
  return (
    <MainWrapper>
      <Helmet>
        <meta
          name='description'
          content='Szeretne egy modern, letisztult arculatot, weboldalt?'
        />
        <title>Digital Creative - Rólunk</title>
      </Helmet>
      <div className='page-bg'></div>
      <section>
        <div className='empty-wrapper'></div>
        <div className='content-wrapper'>
          <h1>Szeretne egy modern, letisztult weboldalt? Beszéljük meg!</h1>

          <p>
            Vállalkozásunk webes technológiákkal - weboldalak készítése, social
            marketing, online grafika - foglalkozik. Csapatunk mindig naprakész
            a webfejlesztés, webdesign és "technikai" SEO legújabb trendjeivel.
          </p>

          <p>
            WordPress alapú és egyedi fejlesztésű weboldalak, webes alkalmazások
            készítésével és ezek karbantartásával foglalkozunk. Megrendelőink
            folyamatos pozitív visszajelzése a legnagyobb motíváció számunkra.
            <br />
            <span className='bold'>Legyen Ön is elégedett ügyfelünk!</span>
          </p>

          <div className='btn-container'>
            <NavLink to='/kapcsolat'>
              <button className='btn'>Kérjen ajánlatot!</button>
            </NavLink>
          </div>
        </div>
      </section>
    </MainWrapper>
  )
}

export default AboutUs

const MainWrapper = styled.main`
  position: relative;
  background-color: #e5e5e7;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    min-height: 100%;
  }
  .page-bg {
    background: url(${bg});
    opacity: 0.55;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (max-width: 1024px) {
      position: relative;
      width: 100%;
      margin-bottom: 5rem;
    }

    .empty-wrapper {
      width: 50%;
    }

    .content-wrapper {
      width: 50%;
      margin-top: 4rem;

      @media screen and (max-width: 1024px) {
        width: 100%;
        margin-top: 2rem;
      }

      h1 {
        margin-bottom: 2rem;
        padding-left: 2rem;
        position: relative;
        font-size: clamp(1.75rem, 1.35rem + 1.3333vw, 2rem);
        font-weight: 500;
        line-height: 1.3;

        @media screen and (max-width: 1024px) {
          width: 100%;
          padding-left: 2rem;
        }

        @media screen and (max-width: 768px) {
          width: 100%;
          margin-left: 0;
          padding-left: 0;

          ::before {
            display: none;
          }

          ::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 20%;
            height: 5px;
            background-color: var(--secondary-red);
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 5px;
          height: 100%;
          background-color: var(--secondary-red);
        }
      }

      p {
        line-height: 1.4;
        margin-bottom: 1rem;

        span {
          line-height: 2.5;
        }
      }
    }
  }
`
