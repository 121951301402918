import Snail from '../assets/references/videoMockup-1.png'
import Fenster from '../assets/references/videoMockup-2.png'
import Math from '../assets/references/videoMockup-4.png'
import BusinessCard from '../assets/references/videoMockup-5.png'
import Food from '../assets/references/videoMockup-6.png'
import Dc from '../assets/references/videoMockup-7.png'
import Bvse from '../assets/references/videoMockup-8.png'

export const mockups = [
  {
    id: 7,
    video: 'https://youtube.com/embed/loovVi6UJ4k',
    thumb: Bvse,
    altTag: 'BVSE bemutatkozó videó',
    desc: 'A Balassagyarmati Vízilabda és Úszó Sportegyesület bemutatkozó videója. A videó elkészítésében a Digital Creative kreatív csapata mellett Makrai Richárd (búvárkodás videó) vett részt.',
  },
  {
    id: 1,
    video: 'https://www.youtube.com/embed/M9_vZA9BZ9A',
    thumb: Snail,
    altTag: 'Digital Creative marketing',
    desc: 'A gyors weboldal megteremtése kulcsfontosságú a látogatók megtartásában, amely végül ügyfelekké teszi őket. Többféle technika áll rendelkezésre, hogy gyors weboldalt készítsünk, amelyeket munkánk során alkalmazunk.',
  },
  {
    id: 2,
    video: 'https://www.youtube.com/embed/TxUYI2Rip04',
    thumb: Fenster,
    altTag: 'Ügyfelünk számára készült videó animáció',
    desc: 'Egy jól elkészített reklámvideó többszörös esély a figyelemfelkeltésre, hatásosabb a közösségi felületeken, erősíti az adott cég brand-jét és könnyen megosztható bármilyen online platformon.',
  },
  {
    id: 3,
    video: 'https://www.youtube.com/embed/MNwdWKRoAnM',
    thumb: Math,
    altTag: 'Ügyfelünk számára készült videó animáció',
    desc: 'Egy jól elkészített reklámvideó többszörös esély a figyelemfelkeltésre, hatásosabb a közösségi felületeken, erősíti az adott cég brand-jét és könnyen megosztható bármilyen online platformon.',
  },
  {
    id: 4,
    video: 'https://www.youtube.com/embed/LMMeYehF7SA',
    thumb: BusinessCard,
    altTag: 'Digital Creative egyedi névjegykártya készítés',
    desc: 'Egyedi névjegykártya készítés! Minőségi alapanyagokból, rövid határidővel. Névjegykártyák az Ön igényeire szabva!',
  },
  {
    id: 5,
    video: 'https://www.youtube.com/embed/VdUTeUBGsIg',
    thumb: Food,
    altTag: 'Ügyfelünk számára készült videóspot',
    desc: 'Mert egy videó sokszor többet mond ezer szónál... Ugye?',
  },
  {
    id: 6,
    video: 'https://www.youtube.com/embed/LZoCAGFdpog',
    thumb: Dc,
    altTag: 'Digital Creative marketing',
    desc: 'A szolgáltatások területén egyre kiélezettebb a verseny. Hogy elérjük a fogyasztót és komolyan is vegyen minket, egy jól megtervezett és megépített weboldal elengedhetetlen. Mi megtervezzük és megalkotjuk vevőszerző online felületét. Kérje árajánlatunkat és kollégaink felveszik Önnel a kapcsolatot. Kezdjük el a közös munkát még ma!',
  },
]
