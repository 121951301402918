import { NavLink, Link } from 'react-router-dom';
import styled from 'styled-components';
import React, { useState, useContext } from 'react';
import { GlobalContext } from '../utils/GlobalContext';

// Import Logos, Images
import logo from '../assets/icons/dc.svg';

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const { setNavClicked } = useContext(GlobalContext);

  function navClickHandle() {
    setNavClicked(true);
  }

  return (
    <Wrapper id='nav'>
      <div className='desktop-menu-wrapper'>
        <NavLink to='/'>
          <span className='logo' onClick={navClickHandle}>
            <img src={logo} alt='Digital Creative' />
          </span>
        </NavLink>
        <div className='desktop-menu'>
          <NavLink to='/' activeclassname='active'>
            <span onClick={navClickHandle}>Főoldal</span>
          </NavLink>
          <NavLink to='/rolunk' activeclassname='active'>
            <span onClick={navClickHandle}>Rólunk</span>
          </NavLink>
          <NavLink to='/szolgaltatasok' activeclassname='active'>
            <span onClick={navClickHandle}>Szolgáltatások</span>
          </NavLink>
          <NavLink to='/blog' activeclassname='active'>
            <span onClick={navClickHandle}>Blog</span>
          </NavLink>
          <NavLink to='/letoltesek' activeclassname='active'>
            <span onClick={navClickHandle}>Letöltések</span>
          </NavLink>
          <NavLink to='/kapcsolat' activeclassname='active'>
            <span onClick={navClickHandle}>Kapcsolat</span>
          </NavLink>
        </div>
      </div>

      <div className='mobile-wrapper'>
        <NavLink to='/'>
          <span className='logo' onClick={navClickHandle}>
            <img src={logo} alt='Digital Creative' />
          </span>
        </NavLink>
        <div className='mobile-nav'>
          <div className='hamburger-ul' onClick={() => setClicked(!clicked)}>
            <div className={`hamburger-1 ${clicked ? 'close-1' : ''}`}></div>
            <div className={`hamburger-2 ${clicked ? 'close-2' : ''}`}></div>
            <div className={`hamburger-3 ${clicked ? 'close-3' : ''}`}></div>
          </div>
          <div className={`mobile-menu ${clicked ? 'menu-active' : ''}`}>
            <div className='nav-items'>
              <Link to='/' activeclassname='active'>
                <span
                  className='mobile-menuitem'
                  onClick={() => setClicked(false)}>
                  Főoldal
                </span>
              </Link>
              <Link to='/rolunk' activeclassname='active'>
                <span
                  className='mobile-menuitem'
                  onClick={() => setClicked(false)}>
                  Rólunk
                </span>
              </Link>
              <Link to='/szolgaltatasok' activeclassname='active'>
                <span
                  className='mobile-menuitem'
                  onClick={() => setClicked(false)}>
                  Szolgáltatások
                </span>
              </Link>
              <Link to='/blog' activeclassname='active'>
                <span
                  className='mobile-menuitem'
                  onClick={() => setClicked(false)}>
                  Blog
                </span>
              </Link>
              <Link to='/letoltesek' activeclassname='active'>
                <span
                  className='mobile-menuitem'
                  onClick={() => setClicked(false)}>
                  Letöltések
                </span>
              </Link>
              <Link to='/kapcsolat' activeclassname='active'>
                <span
                  className='mobile-menuitem'
                  onClick={() => setClicked(false)}>
                  Kapcsolat
                </span>
              </Link>
              <hr />
            </div>

            <ul className='header-bottom'>
              <li>+36(70)530-1130</li>
              <li>info@digitalcreative.hu</li>
              <li>2660 Balassagyarmat, Patvarci út 18.</li>
            </ul>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--main-bg);
  border-bottom: 1px solid var(--secondary-bg);
  z-index: 999;

  .desktop-menu-wrapper {
    display: flex;
    width: 1140px;
    max-width: 90%;
    margin-top: 0;
    height: 95px;
    flex-direction: row;
    list-style: none;
    align-items: center;
    justify-content: space-between;
    font-size: calc(var(--basic-font-size) + 0.1rem);
    gap: 2rem;
    color: var(--secondary-bg);
    background-color: var(--main-bg);

    @media (max-width: 1024px) {
      display: none;
    }
  }
  .logo img {
    max-height: 48px;
    cursor: pointer;
  }

  .desktop-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.8rem;

    span {
      position: relative;
      cursor: pointer;
      text-decoration: none;
      list-style: none;
      position: relative;
      transition: all 0.3s ease;
      font-weight: 700;

      :hover {
        color: var(--secondary-red);
      }

      ::after {
        position: absolute;
        right: -1rem;
        top: 0;
        content: '';
        width: 2px;
        height: 100%;
        background-color: var(--secondary-bg);
      }
    }

    .active span {
      position: relative;
      transition: all 0.3s ease-in-out;
      color: var(--main-red);
    }

    a {
      display: flex;
      justify-content: center;
      color: var(--secondary-bg);
    }
  }

  .mobile-wrapper {
    display: none;
  }

  @media (max-width: 1024px) {
    .mobile-wrapper {
      display: flex;
      width: 100%;
      height: 95px;
      align-items: center;
      justify-content: space-between;
      padding-inline: 10%;
      background: var(--main-bg);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .hamburger-ul {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-evenly;
      width: 22px;
      height: 25px;
      cursor: pointer;
    }

    .hamburger-1,
    .hamburger-2,
    .hamburger-3 {
      width: 20px;
      height: 3px;
      background-color: var(--secondary-bg);
      transition: all 0.3s ease-in-out;
    }

    .close-1 {
      transform: rotate(45deg) translateY(6px);
    }
    .close-3 {
      transform: rotate(-45deg) translateY(-6px) translateX(0.7px);
    }
    .close-2 {
      display: none;
    }

    .mobile-menu {
      position: absolute;
      top: 80px;
      right: 0;
      display: flex;
      flex-direction: column;
      background-color: var(--main-bg);
      width: 100%;
      height: calc(100vh - 80px);
      align-items: center;
      justify-content: space-evenly;
      transform: translateX(100%);
      transition: all 0.3s ease-in-out;
      padding: 2rem 10%;
      list-style: none;

      .nav-items {
        display: flex;
        flex-direction: column;
        height: 50vh;
        align-items: center;
        justify-content: space-between;
      }
    }

    hr {
      margin: 10% 5%;
      border-radius: 0;
      border: none;
      height: 2px;
      width: 90vw;
      background-color: var(--secondary-red);
    }

    .header-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 15%;
      background-color: var(--main-bg);

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        list-style: none;
        margin-block-end: 0.5rem;
        color: var(--secondary-bg);
        padding-block: 0.3rem;
      }

      li p {
        color: var(--primary-bg);
      }

      img {
        padding-right: 0.5rem;
      }
      span {
        font-weight: 700;
      }
    }

    .menu-active {
      transform: translateX(0);
    }

    .mobile-menuitem {
      font-weight: 700;
      position: relative;
      width: 100%;
      transition: all 0.3s ease-in-out;
      text-decoration: none;
      font-size: 1.5rem;
      color: var(--primary-bg);
    }
  }
  button.language-switcher {
    border: none;
    font-size: var(--paragraph-font-size);
    background-color: var(--primary-bg);
    padding: 5px 15px;
    border-radius: 25px;
    transition: all 0.3s ease;
    font-family: var(--text-font-style);

    :hover {
      cursor: pointer;
      color: var(--banner-color);
    }
    :active {
      transform: rotate(360deg);
    }

    @media screen and (max-width: 1024px) {
      padding: 15px;
      border-radius: 50%;
    }
  }
`;

export default Navbar;
