import React from 'react'
import styled from 'styled-components'
// Import logos
import logo from '../assets/icons/dc.svg'
import face from '../assets/icons/facebook.svg'
import insta from '../assets/icons/instagram.svg'
import linkedIn from '../assets/icons/linkedin.svg'
import tiktok from '../assets/icons/tiktok.svg'
import youtube from '../assets/icons/youtube.svg'

import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <Wrapper>
      <div className='content-container'>
        <div className='block block-1'>
          <NavLink to='/'>
            <img src={logo} alt='BePack kft. logo' />
          </NavLink>
        </div>
        <div className='block block-2'>
          <ul>
            <li>
              <NavLink to='/'>Főoldal</NavLink>
            </li>
            <li>
              <NavLink to='/rolunk'>Rólunk</NavLink>
            </li>
            <li>
              <NavLink to='/szolgaltatasok'>Szolgáltatások</NavLink>
            </li>
            <li>
              <NavLink to='/blog'>Blog</NavLink>
            </li>
            <li>
              <NavLink to='/letoltesek'>Letöltések</NavLink>
            </li>
            <li>
              <NavLink to='/kapcsolat'>Kapcsolat</NavLink>
            </li>
          </ul>
        </div>
        <div className='block block-3'>
          <ul>
            <li>
              <NavLink to='/impresszum'>Impresszum</NavLink>
            </li>

            <li>
              <NavLink to='/sutik'>Sütik</NavLink>
            </li>
            <li>
              <NavLink to='/gdpr'>Adatkezelési tájékoztató</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className='block-4'>
        <div className='social-block'>
          <a
            href='https://www.facebook.com/dc.digitalcreative'
            target='_blank'
            rel='noreferrer'
            className='icon'>
            <img src={face} alt='facebook ikon' />
          </a>
          <a
            href='https://www.instagram.com/dc_digitalcreative/'
            target='_blank'
            rel='noreferrer'
            className='icon'>
            <img src={insta} alt='facebook ikon' />
          </a>
          <a
            href='https://www.linkedin.com/company/dc-digitalcreative/'
            target='_blank'
            rel='noreferrer'
            className='icon'>
            <img src={linkedIn} alt='linkedIn ikon' />
          </a>
          <a
            href='https://www.tiktok.com/@digitalcreative_agency'
            target='_blank'
            rel='noreferrer'
            className='icon'>
            <img src={tiktok} alt='tiktok ikon' />
          </a>
          <a
            href='https://www.youtube.com/channel/UC9IL4Nf7q8ldw41lymZr30g'
            target='_blank'
            rel='noreferrer'
            className='icon'>
            <img src={youtube} alt='tiktok ikon' />
          </a>
        </div>
      </div>
      <div className='block-5'>
        <p className='copy'>
          &copy;2022 Digital Creative. Minden jog fenntartva.
        </p>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--secondary-bg);
  background: var(--text-color);

  .content-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: auto;
    padding-top: 4rem;
    padding-bottom: 2rem;
    width: 1140px;
    max-width: 90%;
    height: 50%;
    gap: 5%;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 5%;
      height: 100%;
    }
  }

  li a {
    text-decoration: none;
  }

  p {
    color: var(--primary-bg);
    font-size: 16px;
  }

  .block {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    @media screen and (max-width: 1024px) {
      margin-block: 0.5rem;
    }
  }

  .block-1 {
    width: 20%;
    position: relative;

    img {
      width: 100%;
    }

    @media screen and (max-width: 1024px) {
      margin-bottom: 2rem;
      width: 50%;
    }
  }

  .block-2,
  .block-3 {
    display: flex;
    flex-direction: column;

    ul li {
      list-style-type: none;

      a {
        position: relative;
        color: var(--primary-bg);
        transition: all 0.2s ease;
        padding-bottom: 0.2rem;

        :hover::before {
          position: absolute;
          top: 0;
          left: -8px;
          content: '';
          width: 3px;
          height: 100%;
          background-color: var(--secondary-red);
          /* text-decoration: underline;
          color: var(--header-color); */
        }
      }
    }
    li {
      padding-bottom: 0.5rem;
    }
  }

  .block-4 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;

    .icon {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .icon img {
      width: 30px;
    }
  }

  .block-5 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100px;
    background-color: var(--main-bg);
  }

  .social-block {
    display: flex;
    width: 50%;
    height: 100%;
    flex-direction: row;

    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    margin-block: 2rem;

    a {
      font-size: var(--basic-font-family);
      color: white;
      transition: all 0.2s ease;
      height: 100%;
      display: flex;
      text-decoration: none;

      :hover {
        transform: rotate(45deg);
      }
    }

    @media screen and (max-width: 1024px) {
      width: 50%;
      margin: 1rem auto;

      .block-2 {
        margin-block: 2rem;
      }
    }
  }
`

export default Footer
