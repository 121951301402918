import barside from '../assets/references/barsidemockup.webp'
import barsideTablet from '../assets/references/barsidetablet.png'
import onlinematek from '../assets/references/onlinematek.webp'
import onlinematekTablet from '../assets/references/onlinematektablet.png'
import sophie from '../assets/references/Sophiemockup.webp'
import sophieTablet from '../assets/references/sophietablet.png'
import neked from '../assets/references/nekedmockup.webp'
import nekedTablet from '../assets/references/nekedtablet.png'
import rexsan from '../assets/references/rexsanmockup.webp'
import rexsanTablet from '../assets/references/rexsantablet.png'
import mille from '../assets/references/millemockup.webp'
import milleTablet from '../assets/references/milletablet.png'
import lvnt from '../assets/references/lvntmockup.png'
import lvntTablet from '../assets/references/lvnttablet.png'
import kripteus from '../assets/references/kripteusmockupnew.webp'
import kripteusTablet from '../assets/references/kripteustabletnew.png'
import cooperwebshop from '../assets/references/cooperwebshopmockup.webp'
import cooperwebshopTablet from '../assets/references/cooperwebshoptablet.png'
import theprettybox from '../assets/references/theprettybox.png'
import theprettyboxTablet from '../assets/references/theprettyboxtablet.png'
import silyedelis from '../assets/references/silyedelis.jpg'
import silyedelisTablet from '../assets/references/silyedelis-black_mockup.png'
import silyedelisApartman from '../assets/references/silyedelisapartman.jpg'
import silyedelisApartmanTablet from '../assets/references/silyedelisapartman-black_mockup.png'
import fullmoon from '../assets/references/fullmoon.jpg'
import fullmoonTablet from '../assets/references/fullmoon-dark_mockup.png'
import invensol from '../assets/references/invensolsam.jpg'
import invensolTablet from '../assets/references/invensolSAM-dark_mockup.png'
import bvse from '../assets/references/bvse.jpg'
import bvseTablet from '../assets/references/bvse-dark_mockup.png'

export const mockups = [
  {
    id: 15,
    img: bvse,
    thumb: bvseTablet,
    altTag: 'BVSE',
    desc: 'A Balassagyarmati Vízilabda -és Úszó Sportegyesület weboldala, Wordpress technológia.',
    link: 'https://bvse.hu',
  },
  {
    id: 14,
    img: invensol,
    thumb: invensolTablet,
    altTag: 'Invensol SAM',
    desc: 'Az Invensol Hungary Kft. egyedi design alapján készült weboldala, Wordpress technológia.',
    link: 'https://invensolsam.com',
  },
  {
    id: 13,
    img: fullmoon,
    thumb: fullmoonTablet,
    altTag: 'Full Moon Kids Books',
    desc: 'Full Moon Kids Books weboldala. Angol nyelvű gyermekkönyvek értékesítésével foglalkozó webáruház. Wordpress, Woocommerce.',
    link: 'https://fullmoonkidsbooks.hu',
  },
  {
    id: 12,
    img: silyedelisApartman,
    thumb: silyedelisApartmanTablet,
    altTag: 'Silye De Lis apartman weboldal',
    desc: 'Silye De Lis apartman weboldala. Foglalási naptár, galéria, kikapcsolódási lehetőségek bemutatása. Wordpress oldal.',
    link: 'https://silyedelisapartman.hu',
  },
  {
    id: 11,
    img: silyedelis,
    thumb: silyedelisTablet,
    altTag: 'Silye De Lis portfólió',
    desc: 'Silye Lilla grafikus portfóliója. Egyedi Dekorációs Falfestés, Grafikák, Tusképek, Oktatás. Wordpress oldal.',
    link: 'https://silyedelis.hu',
  },
  {
    id: 1,
    img: barside,
    thumb: barsideTablet,
    altTag: 'Barside',
    desc: 'A The Impulse Kft. megbízásából az Evolut Marketing Group Kft. egyedi designja alapján, a Barside Kft. részére készített Wordpress webshop. A 2015-ben alapított Barside Workline köténymanufaktúra a szakértelmet ötvözi a játékossággal. Az oldal készítésében 3 fős csapat vett részt, melyből 2 fő a Digital Creative fejlesztője volt.',
    link: 'https://barsidebp.com',
  },
  {
    id: 2,
    img: onlinematek,
    thumb: onlinematekTablet,
    altTag: 'Online Matekóra',
    desc: 'Az Online Matekóra a Digital Creative munkatársai által Wordpress alapokon elkészített online kurzus oldal, ahol egyedi készítésű oktató videók megvásárlására, megtekintésére van lehetőség, szélesítve a minőségi oktatáshoz való hozzáférés lehetőségeit.',
    link: 'https://onlinematekora.hu',
  },
  {
    id: 3,
    img: sophie,
    thumb: sophieTablet,
    altTag: 'Sophie Palace',
    desc: 'A The Impulse Kft. megbízásából az Evolut Marketing Group Kft. egyedi designja alapján, a Sophie Palace Kft. részére készített Wordpress weboldal. Az oldal készítésében 3 fős csapat vett részt, melyből 2 fő a Digital Creative fejlesztője volt.',
    link: '',
  },
  {
    id: 4,
    img: neked,
    thumb: nekedTablet,
    altTag: 'neked.hu',
    desc: 'A The Impulse Kft. megbízásából Magyarország egyik legismertebb YouTubere, Rolix egyedi designja alapján, Rolix részére készített Wordpress video-kurzus weboldal. Az oldal készítésében 3 fős csapat vett részt, melyből 2 fő a Digital Creative fejlesztője volt.',
    link: 'https://neked.hu',
  },
  {
    id: 5,
    img: rexsan,
    thumb: rexsanTablet,
    altTag: 'Rexsan',
    desc: 'A The Impulse Kft. megbízásából az Evolut Marketing Group Kft. egyedi designja alapján, a  Rex-San Kft. részére készített Wordpress katalógus weboldal. A Rex-San Kft. az orvostechnikai eszközök gyártásában több mint 30 éves múlttal büszkélkedő magyar vállalat. Az oldal készítésében 3 fős csapat vett részt, melyből 2 fő a Digital Creative fejlesztője volt.',
    link: 'https://rexsan.hu',
  },
  {
    id: 6,
    img: mille,
    thumb: milleTablet,
    altTag: 'Millefiori Dekor',
    desc: 'A Millefiori Dekor a Digital Creative munkatársai által Wordpress alapokon elkészített bemutatkozó oldal, ahol megismerheti Magyarország egyik legszínvonalasabb szolgáltatását nyújtó rendezvényszervező cégét, és a leendő ügyfelek, érdeklődők kapcsolatba is léphetnek velük.',
    link: 'https://millefioridekor.hu/',
  },
  {
    id: 7,
    img: lvnt,
    thumb: lvntTablet,
    altTag: 'LVNT Shop',
    desc: 'Az LVNT Shop a Digital Creative munkatársai által az LVNT International Kft. részére Wordpress alapokon elkészített webshop, ahol gondosan válogatott frissítő italkülönlegességekből vásárolhatsz  a világ minden tájáról.',
    link: 'https://lvntshop.hu/',
  },
  {
    id: 8,
    img: kripteus,
    thumb: kripteusTablet,
    altTag: 'Kripteus',
    desc: 'Az Evolut Marketing Group Kft. megbízásából az Evolut Marketing Group Kft. egyedi designja alapján, az Amadil Consulting Kft. számára készített angol nyelvű weboldal.',
    link: 'https://kripteus.com/',
  },
  {
    id: 9,
    img: cooperwebshop,
    thumb: cooperwebshopTablet,
    altTag: 'Cooper-Klima webshop',
    desc: 'A Cooper-Klima webshop a Digital Creative által saját, egyedi design alapján készített wordpress alapú webshop, ahol az általuk forgalmazott Cooper&Hunter termékek széles választékából vásárolhatsz.',
    link: 'https://cooperklima.hu/',
  },
  {
    id: 10,
    img: theprettybox,
    thumb: theprettyboxTablet,
    altTag: 'The Pretty Box webshop',
    desc: 'A The Pretty Box webshop a Digital Creative által készített wordpress/woocommerce alapú webshop, ahol gyönyörű illatgyertyák, illatviaszok széles választékából vásárolhatsz.',
    link: 'https://theprettybox.hu/',
  },
]
