import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'

import right from '../assets/icons/right.png'

const BlogCard = ({ post }) => {
  const {
    Title,
    Slug,
    Subtitle,
    Created,
    Author,
    Cardimage,
    Category,
    Readingtime,
  } = post.attributes

  return (
    <CardWrapper>
      <NavLink to={`/blog/${Slug}`}>
        <div className='card-top'>
          <div className='card-image'>
            <img loading='lazy' src={Cardimage.data.attributes.url} alt='' />
            <div className='card-category'>
              <p>{Category}</p>
            </div>
          </div>
          <div className='card-top__footer'>
            <p>
              <span>{Author} </span>
              <span>{moment(Created).format('YYYY. MM. DD.')}</span>
            </p>
            <p>Olvasási idő: {Readingtime} perc</p>
          </div>
        </div>
      </NavLink>
      <div className='card-bottom'>
        <h3>{Title}</h3>
        <p>{Subtitle}</p>
      </div>
      <div className='readmore'>
        <NavLink to={`/blog/${Slug}`}>
          <div className='readmore-wrapper'>
            <img src={right} alt='right arrow' />
            <p className='bold'>Tovább olvasok</p>
          </div>
        </NavLink>
      </div>
    </CardWrapper>
  )
}

export default BlogCard

const CardWrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(33.33% - 1.6rem);
  min-width: 300px;
  height: fit-content;
  margin: 0.8rem;
  margin-bottom: 3rem;

  a {
    text-decoration: none;
    color: var(--text-color);

    :hover .card-image img {
      filter: none;
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 4rem;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 2.5rem;
  }

  .card-top {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    border: 3px solid #e5e5e5;
    padding: 30px 15px;
    height: 100%;

    .card-image {
      position: relative;
      display: flex;
      align-items: flex-start;
      width: 100%;
      height: 320px;
      text-align: center;
      object-fit: cover;
      overflow: hidden;

      @media screen and (max-width: 768px) {
        height: 100%;
      }

      .card-category {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        background-image: linear-gradient(
          0deg,
          rgba(226, 48, 55, 0.7),
          rgba(226, 48, 55, 0.7)
        );
        width: 75%;
        height: 12%;
        display: flexbox;
        align-items: center;
        text-transform: uppercase;
        color: var(--secondary-bg);

        p {
          padding-left: 1rem;
          opacity: 1;
        }
      }
    }

    .card-image img {
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      filter: grayscale(100%);
    }
    .card-top__footer {
      margin-top: 1.2rem;
    }
  }

  .card-bottom {
    display: flex;
    flex-direction: column;
    padding: 30px 15px;
    height: 250px;

    @media screen and (max-width: 1024px) {
      height: auto;
    }
    h3 {
      font-size: clamp(1.375rem, 1.255rem + 0.4vw, 1.75rem);
      font-weight: 400;
      margin-bottom: 1rem;
    }

    p {
      font-size: calc(var(--basic-font-size) + 2px);
    }
  }

  .readmore {
    padding: 0 15px;
    display: flex;

    a {
      text-decoration: none;
      color: var(--text-color);
      transition: all 0.3s ease;

      :hover {
        transform: scale(1.1);
      }
    }

    .readmore-wrapper {
      display: flex;
      align-items: center;

      img {
        width: 20px;
      }

      p {
        margin-left: 0.5rem;
      }
    }
  }
`
