import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'

import 'swiper/css'

const isMobile = window.innerWidth < 480
const Slider = ({ datas }) => {
  return (
    <Wrapper className='slider-block'>
      <div className='slider-wrapper'>
        {!isMobile ? (
          <Swiper
            modules={[Autoplay]}
            slidesPerView={4}
            spaceBetween={50}
            autoplay={{
              delay: 3500,
            }}
            loop={true}>
            <>
              {datas.map((data) => (
                <SwiperSlide key={data.id}>
                  <img src={data.img} alt={data.altTag} />
                </SwiperSlide>
              ))}
            </>
          </Swiper>
        ) : (
          <Swiper
            modules={[Autoplay]}
            slidesPerView={1}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
            }}
            loop={true}>
            <>
              {datas.map((data) => (
                <SwiperSlide key={data.id}>
                  <img src={data.img} alt={data.altTag} />
                </SwiperSlide>
              ))}
            </>
          </Swiper>
        )}
      </div>
    </Wrapper>
  )
}

export default Slider

const Wrapper = styled.div`
  background-color: var(--main-bg);
  /* background-color: #e5e5e5; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 2rem;

  .slider-wrapper {
    width: 1140px;
    max-width: 90%;
    height: 100%;

    img {
      width: 90%;
      height: auto;

      @media screen and (max-width: 480px) {
        width: 70%;
      }
    }
  }
  .swiper-slide {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 480px) {
      justify-content: center;
    }
  }
`
