import styled from 'styled-components'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'

import References from '../components/References'
import { mockups } from '../utils/VideoRefData'

// import logos, images, videos
import marker from '../assets/icons/mini-marker.png'
import right from '../assets/icons/right.png'
import movie from '../assets/icons/movie.png'
import bg from '../assets/images/video_cover.webp'
import dc from '../assets/images/dc_shine.webp'
import plus from '../assets/icons/plus.svg'
import spot1 from '../assets/videos/spot-1.mp4'
// import spot2 from '../assets/videos/spot-2.mp4'
import spot3 from '../assets/videos/video.mp4'
import spot4 from '../assets/videos/fensterbau.mp4'

const VideoServices = () => {
  const [clickedVideo, setClickedVideo] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(null)

  const handelRotationRight = () => {
    const totalLength = mockups.length
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0)
      const newVideo = mockups[0]
      setClickedVideo(newVideo)
      return newVideo
    }
    const newIndex = currentIndex + 1
    const newVideo = mockups.filter((item) => {
      return mockups.indexOf(item) === newIndex
    })
    const newItem = newVideo[0]
    setClickedVideo(newItem)
    setCurrentIndex(newIndex)
    return newItem
  }

  const handelRotationLeft = () => {
    const totalLength = mockups.length
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1)
      const newVideo = mockups[totalLength - 1]
      setClickedVideo(newVideo)
      return newVideo
    }
    const newIndex = currentIndex - 1
    const newVideo = mockups.filter((item) => {
      return mockups.indexOf(item) === newIndex
    })
    const newItem = newVideo[0]
    setClickedVideo(newItem)
    setCurrentIndex(newIndex)
    return newItem
  }
  return (
    <MainWrapper>
      <Helmet>
        <meta name='description' content='Digital Creative - Video Spotok' />
        <title>Digital Creative - Video spotok</title>
      </Helmet>
      <div className='page-hero'>
        <div className='hero-title'>
          <img src={dc} alt='Digital Creative logo' />
        </div>
      </div>
      <section>
        <div className='content-wrapper'>
          <div className='first-block'>
            <div className='grey-bg'>
              <img src={movie} alt='' />
            </div>
            <div className='top-content'>
              <h2>
                Rövid <span className='red'>reklámvideók</span> készítése
                Facebookra, Instagramra, <span className='red'>weboldalra</span>
              </h2>
              <div className='textblock'>
                <p>
                  <span className='bold'>A videó olyan vizuális tartalom</span>,
                  amely minden esetben jobban eléri a célját a hagyományos
                  állóképnél.
                </p>
                <p>
                  Egy jól elhelyezett reklámspot nagyon látványos és hatásos
                  marketing elem lesz az Ön online felületén.
                </p>
              </div>
              <div className='textblock'>
                <p>
                  A reklámvideó sokoldalú, könnyen emészthető és vonzó
                  formátumban képes fontos információkat közölni. Ráadásul az
                  emberi agy 60.000-szer gyorsabban dolgozza fel a képeket, mint
                  a szövegeket! Könnyen belátható, hogy miért van egyre nagyobb
                  kereslet a cégek részéről a hasonló produkciós
                  szolgáltatásokra. Ha jelenlétét szeretné növelni a piacon, a
                  videós reklámspotok produktív és gyorsan megtérülő
                  befektetésnek számítanak.
                </p>
              </div>
            </div>
            <div className='bottom-content'>
              <div className='text-content'>
                <h2>
                  A <span className='red'>jó reklámvideó</span> előnyei:
                </h2>
                <ul>
                  <li>Többszörös esély a figyelemfelkeltésre</li>
                  <li>Extra információk megjeleníthetősége</li>
                  <li>Biztosan végignézi a látogató</li>
                  <li>Hatásosabb a közösségi felületeken</li>
                  <li>Erősíti az adott cég brand-jét</li>
                  <li>Könnyen megosztható az online platformokon</li>
                </ul>
              </div>
              <div className='video-player'>
                <video playsInline autoPlay muted loop className='spot1'>
                  <source src={spot4} type='video/mp4'></source>
                </video>
              </div>
            </div>
          </div>
          <div className='second-block'>
            <div className='video-player'>
              <video playsInline autoPlay muted loop className='spot1'>
                <source src={spot3} type='video/mp4'></source>
              </video>
            </div>
            <div className='text-content'>
              <h2>
                Könnyen érthető és
                <span className='red'> ösztönző vizuális</span> tartalom
              </h2>
              <div className='textblock'>
                <p>
                  <span className='bold'>A reklám célja</span>, hogy felkeltse a
                  figyelmet, érdeklődést generáljon és lelkesedést váltson ki az
                  Ön termékéért, vagy szolgáltatásáért.
                </p>
                <div className='textblock'>
                  <p>
                    <span className='bold'>
                      Egy igényesen elkészített videós tartalom{' '}
                    </span>
                    bevonzza a potenciális ügyfeleket, népszerűsíti márkája
                    üzenetét, és a legtöbbet hozza ki az adott felületen való
                    megjelenésből.
                  </p>
                </div>

                <div className='more'>
                  <NavLink to='/kapcsolat'>
                    <div className='more-wrapper'>
                      <img src={right} alt='right arrow' />
                      <p className='bold'>Szeretnék egy profi videót!</p>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mockup-wrapper'>
        <h2>Marketing videó referenciák</h2>
        <div className='mockup-container'>
          {mockups.map((mockup) => (
            <div
              key={mockup.id}
              className='img-container'
              onClick={() => {
                setClickedVideo(mockup)
              }}>
              <div className='thumbnail-container'>
                <div className='thumbnail'>
                  <img src={mockup.thumb} alt={mockup.altTag} />
                </div>
                <div className='plus'>
                  <img src={plus} alt={mockup.altTag} />
                </div>
              </div>
            </div>
          ))}
        </div>

        {clickedVideo && (
          <References
            clickedVideo={clickedVideo}
            handelRotationRight={handelRotationRight}
            setClickedVideo={setClickedVideo}
            handelRotationLeft={handelRotationLeft}
          />
        )}
      </section>
      <video playsInline autoPlay muted loop className='spot'>
        <source src={spot1} type='video/mp4'></source>
      </video>
      <div className='btn-container bg-black'>
        <NavLink to='/szolgaltatasok'>
          <button className='btn'>Vissza a szolgáltatásokhoz</button>
        </NavLink>
      </div>
    </MainWrapper>
  )
}

export default VideoServices

const MainWrapper = styled.main`
  .page-hero {
    position: relative;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: center;
    height: 433px;
    width: 100%;
    background-size: cover;

    @media screen and (max-width: 1024px) {
      background-position-y: center;
      background-position-x: 50%;
      height: 50vh;
    }

    .hero-title {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, 0);
      animation: videoherotitle 2s;

      @media screen and (max-width: 1024px) {
        bottom: 80px;
        width: 70%;
        animation: videoherotitle-mobil 2s;
      }

      img {
        width: 90%;

        @media screen and (max-width: 480px) {
          width: 120%;
        }
      }
    }

    @keyframes videoherotitle {
      from {
        bottom: 500px;
      }
      to {
        bottom: 20px;
      }
    }

    @keyframes videoherotitle-mobil {
      from {
        bottom: 500px;
      }
      to {
        bottom: 80px;
      }
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    margin: 3rem 0 5rem;

    .first-block {
      position: relative;
      width: 100%;
      height: 100%;
      padding-left: 7%;

      @media screen and (max-width: 1024px) {
        padding-left: 0;
      }

      .grey-bg {
        position: absolute;
        right: 0;
        top: 0;
        height: 99.5%;
        width: 50%;
        background-color: var(--main-gray);
        z-index: -1;

        img {
          position: absolute;
          width: 84px;
          height: 84px;
          right: 30%;
          top: 8%;
          animation: videofade 2s ease, videospin 1s ease 2.2s alternate;
        }

        @keyframes videofade {
          0% {
            display: none;
            scale: 0;
            opacity: 0;
          }
          100% {
            display: block;
            scale: 1;
            opacity: 1;
          }
        }

        @keyframes videospin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        @media screen and (max-width: 1024px) {
          display: none;
        }
      }

      .top-content {
        display: flex;
        flex-direction: column;
        width: 90%;

        @media screen and (max-width: 1024px) {
          width: 100%;
        }

        h2 {
          position: relative;
          width: 60%;
          font-size: clamp(1.5rem, 1.26rem + 0.8vw, 2.25rem);
          margin-left: 1rem;
          margin-bottom: 1rem;

          @media screen and (max-width: 1024px) {
            width: 100%;
          }

          @media screen and (max-width: 768px) {
            width: 100%;
            margin-left: 0;

            ::before {
              display: none;
            }

            ::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: -5px;
              width: 20%;
              height: 5px;
              background-color: var(--secondary-red);
            }
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -2rem;
            width: 5px;
            height: 100%;
            background-color: var(--secondary-red);
          }
        }
      }

      .bottom-content {
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        width: 100%;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        .text-content {
          display: flex;
          flex-direction: column;
          width: 50%;

          @media screen and (max-width: 768px) {
            width: 100%;
            margin-bottom: 1rem;
          }

          h2 {
            font-size: clamp(1.5rem, 1.26rem + 0.8vw, 2.25rem);
            margin-bottom: 2rem;
          }

          li {
            position: relative;
            list-style: none;
            line-height: 2;
            margin-left: 30px;

            ::before {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              content: url(${marker});
              width: 16px;
              height: 16px;
              top: 10px;
              left: -30px;
            }
          }
        }
      }
    }

    .second-block {
      display: flex;
      justify-content: space-between;
      margin-top: 5rem;
      width: 100%;
      height: 100%;
      gap: 2rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 0;
      }

      .text-content {
        display: flex;
        flex-direction: column;
        width: 50%;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        p {
          margin-left: 1rem;

          @media screen and (max-width: 768px) {
            margin-left: 0;
          }
        }

        h2 {
          position: relative;
          width: 100%;
          font-size: clamp(1.5rem, 1.26rem + 0.8vw, 2.25rem);
          margin-left: 1rem;
          margin-bottom: 1rem;

          @media screen and (max-width: 1024px) {
            width: 100%;
          }

          @media screen and (max-width: 768px) {
            width: 100%;
            margin-left: 0;

            ::before {
              display: none;
            }

            ::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: -5px;
              width: 20%;
              height: 5px;
              background-color: var(--secondary-red);
            }
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -2rem;
            width: 5px;
            height: 100%;
            background-color: var(--secondary-red);
          }
        }
      }
    }
  }

  .video-player {
    width: 50%;

    @media screen and (max-width: 768px) {
      width: 100%;
      order: 2;
    }

    video {
      width: 100%;
      height: auto;
    }
  }

  .more {
    padding: 0 15px;
    display: flex;
    @media screen and (max-width: 768px) {
      padding: 0;
    }

    a {
      text-decoration: none;
      color: var(--text-color);
    }

    .more-wrapper {
      display: flex;
      align-items: center;
      transition: all 0.3s ease;

      img {
        width: 20px;
      }

      p {
        padding-left: 0.5rem;
      }

      :hover {
        transform: scale(1.1);
      }
    }
  }
  .mockup-wrapper {
    margin-block: 3rem;

    h2 {
      position: relative;
      width: 100%;
      font-size: clamp(1.5rem, 1.26rem + 0.8vw, 2.25rem);
      margin-left: 2rem;
      margin-bottom: 2rem;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        margin-left: 0;

        ::before {
          display: none;
        }

        ::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -5px;
          width: 20%;
          height: 5px;
          background-color: var(--secondary-red);
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -2rem;
        width: 5px;
        height: 100%;
        background-color: var(--secondary-red);
      }
    }

    .mockup-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;

      @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
      }

      .img-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        cursor: pointer;

        .thumbnail-container {
          position: relative;
          width: 100%;
          height: 100%;

          :hover .plus {
            opacity: 1;
          }

          .thumbnail {
            width: 100%;
            height: 100%;
            background-color: #dbdbdb;
            padding: 1.5rem;
            display: grid;
            place-content: center;
            img {
              width: 100%;
              height: auto;
              object-fit: cover;
              transition: all 0.3s ease;
              filter: brightness(0.9);
            }
          }

          .plus {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--secondary-red);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;
            filter: opacity(0.75);
            img {
              width: 20%;
              height: 20%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
`
