import mille from '../assets/logos/mille.png'
import om from '../assets/logos/om.png'
import virtual from '../assets/logos/virtual.png'
import fenster from '../assets/logos/fenster.png'
import easy from '../assets/logos/easyfeherfull.png'
import bepack from '../assets/logos/bepack.png'
import helloWp from '../assets/logos/hellowp-logo.png'
import cooperklima from '../assets/logos/cooperklima.png'

export const partners = [
  { id: 1, img: mille, altTag: 'Millefiori Dekor' },
  { id: 2, img: om, altTag: 'Online Matekóra' },
  { id: 3, img: virtual, altTag: 'Virtual Consulting Kft.' },
  { id: 4, img: fenster, altTag: 'Fenster-Bau Kft.' },
  { id: 5, img: easy, altTag: 'Easylot' },
  { id: 6, img: bepack, altTag: 'BE-Pack Kft.' },
  { id: 7, img: helloWp, altTag: 'helloWP' },
  { id: 8, img: cooperklima, altTag: 'Cooper-Klíma Kft.' }
]
