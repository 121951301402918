import seagull from '../assets/mockups/seagull.webp'
import mille from '../assets/mockups/mille.webp'
import berenyi from '../assets/mockups/berenyi.webp'
import yeti from '../assets/mockups/yeti.webp'
import easylot from '../assets/mockups/easylot1.webp'
import smaragd from '../assets/mockups/smaragd.webp'
import top from '../assets/mockups/top.webp'
import petbar from '../assets/mockups/petbar11.webp'
import step from '../assets/mockups/step.webp'
import greta from '../assets/mockups/greta-agro.jpg'
import tamasCard from '../assets/mockups/tamas-card.webp'
import easyCard from '../assets/mockups/easy_card.webp'

export const mockups = [
  { id: 1, img: seagull, altTag: 'Seagull logó' },
  { id: 2, img: mille, altTag: 'Mille logó' },
  { id: 3, img: berenyi, altTag: 'Berényi logó' },
  { id: 4, img: yeti, altTag: 'Yeti shop logó' },
  { id: 5, img: easylot, altTag: 'Easylot logó' },
  { id: 6, img: smaragd, altTag: 'Smaragd kertészet logó' },
  { id: 7, img: top, altTag: 'Top cycles logó' },
  { id: 8, img: petbar, altTag: 'PetBar logó' },
  { id: 9, img: step, altTag: 'Step by Step logó' },
  { id: 10, img: easyCard, altTag: 'Easylot kft. névjegykártya' },
  {
    id: 11,
    img: tamasCard,
    altTag: 'Majoros Tamás - Digital Creative névjegykártya',
  },
  { id: 12, img: greta, altTag: 'Gréta-Agro kft. névjegykártya' },
]
